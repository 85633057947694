import React, { useEffect, useState } from "react";
import {
  FaBullhorn,
  FaCalendarAlt,
  FaCog,
  FaHome,
  FaUniversity,
  FaUsers,
  FaUserShield,
} from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { get } from "../../utils/fetchAPI";

const ToolTip = ({ text, isVisible }) => (
  <div
    className={`absolute left-full top-1/2 transform -translate-y-1/2 ml-2 w-32 p-2 text-sm text-white bg-gray-800 rounded shadow-lg transition-opacity duration-200 ${
      isVisible ? "opacity-100" : "opacity-0"
    }`}
  >
    {text}
  </div>
);

const SideBar = ({ isCollapsed, setIsCollapsed }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showAdminLink, setShowAdminLink] = useState(false);
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const location = useLocation();

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await get("/admin/admins/get-details");
        const permissions = response.data.data.role.permissions;
        const hasAdminListPermission = permissions.some(
          (permission) => permission.slug === "admin-list"
        );
        setShowAdminLink(hasAdminListPermission);
      } catch (error) {
        console.error("Error fetching admin details:", error);
      }
    };

    fetchAdminDetails();
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-primary text-white flex flex-col transition-all duration-300 ${
        isCollapsed ? "w-16" : "w-64"
      } z-50`}
    >
      <div className="flex items-center justify-between p-4">
        <button onClick={toggleSidebar} className="p-2">
          <HiMenuAlt2 className="w-8 h-8" />
        </button>
        {!isCollapsed && (
          <div className="flex-1 flex justify-center items-center text-xl font-bold">
            Admin Portal
          </div>
        )}
      </div>
      <nav className="flex-1 flex flex-col mt-8">
        {[
          {
            to: "/dashboard",
            icon: <FaHome className="w-6 h-6" />,
            text: "Dashboard",
          },
          {
            to: "/users",
            icon: <FaUsers className="w-6 h-6" />,
            text: "Users",
          },
          {
            to: "/events/view",
            icon: <FaCalendarAlt className="w-6 h-6" />,
            text: "Events",
          },
          {
            to: "/clubs",
            icon: <FaUniversity className="w-6 h-6" />,
            text: "Clubs",
          },
          {
            to: "/bulletins",
            icon: <FaBullhorn className="w-6 h-6" />,
            text: "Bulletins",
          },
          ...(showAdminLink
            ? [
                {
                  to: "/admins",
                  icon: <FaUserShield className="w-6 h-6" />,
                  text: "Admins",
                },
              ]
            : []),
          {
            to: "/settings",
            icon: <FaCog className="w-6 h-6" />,
            text: "Settings",
          },
        ].map((item, index) => (
          <div
            className="relative group"
            key={item.text}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <Link
              to={item.to}
              className={`flex items-center p-2 rounded w-full hover:bg-gray-700 ${
                location.pathname === item.to ? "bg-gray-600" : ""
              }`}
            >
              <div
                className="flex items-center justify-center"
                style={{ width: "50px" }}
              >
                {item.icon}
              </div>
              {!isCollapsed && (
                <span className="ml-1 text-left">{item.text}</span>
              )}
            </Link>
            {isCollapsed && hoveredIndex === index && (
              <ToolTip text={item.text} isVisible={true} />
            )}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default SideBar;
