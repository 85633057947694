import JoditEditor from "jodit-react";
import React, { useMemo, useRef } from "react";

const JoditEditorComponent = ({ content, onChange }) => {
  const editorRef = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
      toolbarSticky: false,
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "superscript",
        "subscript",
        "|",
        "ul",
        "ol",
        "outdent",
        "indent",
        "|",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "|",
        "image",
        "video",
        "file",
        "table",
        "|",
        "link",
        "unlink",
        "|",
        "align",
        "undo",
        "redo",
        "|",
        "cut",
        "copy",
        "paste",
        "|",
        "hr",
        // "symbol",
        "fullsize",
        "print",
        "|",
        "selectall",
        "preview",
        "code",
      ],
      uploader: {
        insertImageAsBase64URI: true,
      },
      //   toolbarSticky: true,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: true,
      statusbar: true,
      language: "en",
      spellcheck: true,
      showPoweredBy: false,
      minHeight: 400,
      height: "auto",
      overflow: "hidden",
    }),
    []
  );

  return (
    <JoditEditor
      ref={editorRef}
      value={content}
      config={config}
      onChange={(newContent) => onChange(newContent)}
      className="border border-gray-300 rounded"
    />
  );
};

export default JoditEditorComponent;
