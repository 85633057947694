import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faTimesCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import LoaderGif from "../../components/Loader.gif";
import { get, getWithParams } from "../../utils/fetchAPI";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [clubSummary, setClubSummary] = useState(null);
  const [focusData, setFocusData] = useState([]);
  const [referralData, setReferralData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [clubFocusAreaData, setClubFocusAreaData] = useState([]);
  const [clubSpiritData, setClubSpiritData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [membersGenderData, setMembersGenderData] = useState([]);
  const [memberProfessionData, setMemberProfessionData] = useState([]);
  const [ageDistributionData, setAgeDistributionData] = useState([]);
  const [timeToClubCount, setTimeToClubCount] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [countries, setCountries] = useState([]);

  const [cities, setCities] = useState([]);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    clubStatus: "",
    countryId: "",
    cityId: "",
  });
  const [loading, setLoading] = useState(false);

  const [searchClicked, setSearchClicked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const response = await getWithParams("admin/dashboards", filters);
        if (response.data.success) {
          const {
            clubSummery,
            club3zeroFocusData,
            howDidYouHearData,
            locationData,
            clubFocusAreaData,
            clubSpiritData,
            regionData,
            membersGenderData,
            memberProfessionData,
            ageDistributionData,
            timeToClubCount,
            monthData,
          } = response.data.data;

          setClubSummary(clubSummery[0]);
          setFocusData(club3zeroFocusData);
          setReferralData(howDidYouHearData);
          setLocationData(locationData);
          setClubFocusAreaData(clubFocusAreaData);
          setClubSpiritData(clubSpiritData);
          setRegionData(regionData);
          setMembersGenderData(membersGenderData);
          setMemberProfessionData(memberProfessionData);
          setAgeDistributionData(ageDistributionData);
          setTimeToClubCount(timeToClubCount);
          setMonthData(monthData);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        localStorage.removeItem("user");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    if (searchClicked) {
      fetchDashboardData();
      setSearchClicked(false);
    }

    fetchDashboardData();
  }, [searchClicked]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await get("/geo-locations/countries");
        if (response.data.success) {
          setCountries(response.data.data);
        } else {
          console.error("Failed to fetch countries:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const fetchCities = async (countryId) => {
    try {
      const response = await getWithParams(`/geo-locations/cities`, {
        countryId,
      });
      if (response.status === 200) {
        setCities(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    if (filters.countryId) {
      fetchCities(filters.countryId);
    }
  }, [filters.countryId]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));

    if (name === "countryId") {
      setFilters((prev) => ({ ...prev, cityId: "" }));
      if (value) {
        fetchCities(value);
      } else {
        setCities([]);
      }
    }
  };

  const handleReset = () => {
    setFilters({
      countryId: "",
      cityId: "",
      startDate: "",
      endDate: "",
      clubStatus: "",
    });
    setCities([]);
    setSearchClicked(true);
  };

  const handleSearch = () => {
    setSearchClicked(true);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <img
          src={LoaderGif}
          alt="Loading..."
          style={{ width: "300px", height: "300px" }}
        />
      </div>
    );
  }

  if (!clubSummary) {
    return <div>No data available</div>;
  }

  const stats = [
    {
      label: "Total Valid Clubs",
      value: clubSummary.submittedValidTotal,
      icon: faUsers,
      color: "bg-blue-500",
    },
    {
      label: "Pending Clubs",
      value: clubSummary.pendingTotal,
      icon: faClock,
      color: "bg-yellow-500",
    },
    {
      label: "Processing Clubs",
      value: clubSummary.processingTotal,
      icon: faExclamationTriangle,
      color: "bg-orange-500",
    },
    {
      label: "Approved Clubs",
      value: clubSummary.approvedTotal,
      icon: faCheckCircle,
      color: "bg-green-500",
    },
    {
      label: "Rejected Clubs",
      value: clubSummary.rejectedTotal,
      icon: faTimesCircle,
      color: "bg-red-500",
    },
  ];

  return (
    <div className="mx-auto p-4 bg-gray-100">
      <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Club Dashboard</h2>
        <div className="flex flex-wrap items-center gap-2 overflow-x-auto">
          {/* Start Date */}
          <div className="w-40">
            <label
              htmlFor="startDate"
              className="block text-sm text-gray-500 mb-1"
            >
              Select Start Date
            </label>
            <input
              id="startDate"
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full h-10"
            />
          </div>

          {/* End Date */}
          <div className="w-40">
            <label
              htmlFor="endDate"
              className="block text-sm text-gray-500 mb-1"
            >
              Select End Date
            </label>
            <input
              id="endDate"
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full h-10"
            />
          </div>

          {/* Club Status */}
          <div className="w-45">
            <label
              htmlFor="clubStatus"
              className="block text-sm text-gray-500 mb-1"
            >
              Select Club Status
            </label>
            <select
              id="clubStatus"
              name="clubStatus"
              value={filters.clubStatus}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full h-10"
            >
              <option value="">Club Status</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>

          {/* Country */}
          <div className="w-40">
            <label
              htmlFor="countryId"
              className="block text-sm text-gray-500 mb-1"
            >
              Select Country
            </label>
            <select
              id="countryId"
              name="countryId"
              value={filters.countryId}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full h-10"
            >
              <option value="">Country</option>
              {countries && countries.length > 0 ? (
                countries.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.name}
                  </option>
                ))
              ) : (
                <option value="">Loading countries...</option>
              )}
            </select>
          </div>

          {/* City */}
          <div className="w-40">
            <label
              htmlFor="cityId"
              className="block text-sm text-gray-500 mb-1"
            >
              Select City
            </label>
            <select
              id="cityId"
              name="cityId"
              value={filters.cityId}
              onChange={handleFilterChange}
              className="p-2 border rounded w-full h-10"
              disabled={!filters.countryId}
            >
              <option value="">City</option>
              {cities.map((city) => (
                <option key={city._id} value={city._id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          {/* Grouped Buttons */}
          <div className="flex gap-2 ml-auto mt-5">
            <button
              onClick={handleSearch}
              className="p-1 bg-blue-500 text-white rounded w-24"
            >
              Search
            </button>
            <button
              onClick={handleReset}
              className="p-1 bg-red-500 text-white rounded w-24"
            >
              Reset
            </button>
          </div>
        </div>
      </section>

      <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">
          Club Registration Summary
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {stats.map((stat, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center p-6 rounded-lg shadow-md text-white ${stat.color} transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-out min-h-[120px]`}
            >
              <FontAwesomeIcon icon={stat.icon} size="2x" className="mb-2" />
              <span className="text-sm font-semibold">{stat.label}</span>
              <div className="text-2xl font-bold">{stat.value}</div>
            </div>
          ))}
        </div>
      </section>

      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 h-100">
        <div className="flex-1 bg-gray-100 rounded-lg p-4">
          <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Club Registration: Approval Status
            </h2>
            <div className="flex justify-center">
              <div style={{ width: "200px", height: "200px" }}>
                <Pie
                  data={{
                    labels: ["Approved", "Pending", "Processing", "Rejected"],
                    datasets: [
                      {
                        data: [
                          clubSummary.approvedTotal,
                          clubSummary.pendingTotal,
                          clubSummary.processingTotal,
                          clubSummary.rejectedTotal,
                        ],
                        backgroundColor: [
                          "#4caf50",
                          "#ffeb3b",
                          "#ff9800",
                          "#f44336",
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (tooltipItem) {
                            const value = tooltipItem.raw;
                            const total = tooltipItem.dataset.data.reduce(
                              (a, b) => a + b,
                              0
                            );
                            const percentage = ((value / total) * 100).toFixed(
                              2
                            );
                            return `${tooltipItem.label}: ${percentage}%`;
                          },
                        },
                      },
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>

              <div className="ml-2 text-sm space-y-2">
                <div className="flex items-center">
                  <div className="w-4 h-4 bg-green-500 mr-1"></div> Approved:{" "}
                  {clubSummary.approvedTotal}
                </div>
                <div className="flex items-center">
                  <div className="w-4 h-4 bg-yellow-500 mr-1"></div> Pending:{" "}
                  {clubSummary.pendingTotal}
                </div>
                <div className="flex items-center">
                  <div className="w-4 h-4 bg-orange-500 mr-1"></div> Processing:{" "}
                  {clubSummary.processingTotal}
                </div>
                <div className="flex items-center">
                  <div className="w-4 h-4 bg-red-500 mr-1"></div> Rejected:{" "}
                  {clubSummary.rejectedTotal}
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="flex-1 bg-gray-100 rounded-lg p-4">
          <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Club Registration: Regions
            </h2>
            <div className="flex justify-center">
              <div style={{ width: "200px", height: "200px" }}>
                <Pie
                  data={{
                    labels: regionData.map((region) => region._id),
                    datasets: [
                      {
                        data: regionData.map((region) => region.count),
                        backgroundColor: regionData.map((region) => {
                          const colors = {
                            Asia: "#4caf50",
                            Europe: "#ffeb3b",
                            Americas: "#ff9800",
                            Oceania: "#f44336",
                            Africa: "#2196f3",
                          };
                          return colors[region._id] || "#cccccc";
                        }),
                        hoverOffset: 4,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                />
              </div>
              <div className="ml-2 text-sm space-y-2">
                {regionData.map((region) => {
                  const colors = {
                    Asia: "#4caf50",
                    Europe: "#ffeb3b",
                    Americas: "#ff9800",
                    Oceania: "#f44336",
                    Africa: "#2196f3",
                  };

                  return (
                    <div key={region._id} className="flex items-center">
                      <div
                        className="w-4 h-4 mr-1"
                        style={{
                          backgroundColor: colors[region._id] || "#cccccc",
                        }}
                      ></div>{" "}
                      {region._id}: {region.count}
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="mt-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">
            Club Registration: 3ZERO Focus Areas
          </h2>
          <Bar
            data={{
              labels: focusData
                // .sort(() => Math.random() - 0.5)
                .map((item) => item._id.replace(/_/g, " ")),
              datasets: [
                {
                  data: focusData.map((item) => item.count),
                  backgroundColor: ["#42a5f5", "#66bb6a", "#ffca28"],
                },
              ],
            }}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Club Registration",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "3ZERO Focus Area",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </section>

        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">
            Club Registration: Referral Sources
          </h2>
          <Bar
            data={{
              labels: referralData.map((item) => item._id || "Unknown"),
              datasets: [
                {
                  data: referralData.map((item) => item.count),
                  backgroundColor: [
                    "#ab47bc",
                    "#29b6f6",
                    "#78909c",
                    "#8d6e63",
                    "#ffa726",
                  ],
                },
              ],
            }}
            // options={{ indexAxis: "y" }}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Club Registration",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Media",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </section>
      </div>

      <section className="mt-6 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-2">
          Country Wise Club Registration
        </h2>
        <Bar
          data={{
            labels: locationData.map((item) => item._id),
            datasets: [
              {
                data: locationData.map((item) => item.count),
                backgroundColor: [
                  "#42a5f5",
                  "#66bb6a",
                  "#ffca28",
                  "#ef5350",
                  "#29b6f6",
                  "#8d6e63",
                ],
              },
            ],
          }}
          options={{
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Country",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Club Registration",
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </section>

      <section className="mt-6 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-2">
          Days for every 100 Club Registration
        </h2>
        <Bar
          data={{
            labels: timeToClubCount.map((item) => item.number_of_100),
            datasets: [
              {
                data: timeToClubCount.map((item) => item.total_days),
                backgroundColor: [
                  "#42a5f5",
                  "#66bb6a",
                  "#ffca28",
                  "#ef5350",
                  "#29b6f6",
                  "#8d6e63",
                ],
                hoverBackgroundColor: "#64b5f6",
              },
            ],
          }}
          options={{
            scales: {
              x: {
                title: {
                  display: true,
                  text: "100 Clubs Registration",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Days Taken",
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const item = timeToClubCount[tooltipItem.dataIndex];
                    return `Days: ${item.total_days}`;
                  },
                },
              },
            },
          }}
        />
      </section>

      <section className="mt-6 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-2">
          Month-wise Club Registration
        </h2>
        <Bar
          data={{
            labels: monthData.map((item) => {
              const [month, year] = item.month.split(",");
              return `${month}, ${year}`;
            }),
            datasets: [
              {
                data: monthData.map((item, index) => {
                  return item.count;
                }),
                backgroundColor: monthData.map((item, index) => {
                  const colors = [
                    "#42a5f5", // Blue
                    "#ef5350", // Red
                    "#66bb6a", // Green
                    "#ffca28", // Yellow
                    "#29b6f6", // Light Blue
                    "#8d6e63", // Brown
                  ];

                  return index % 2 === 0 ? colors[0] : colors[1];
                }),
                hoverBackgroundColor: "#64b5f6",
              },
            ],
          }}
          options={{
            scales: {
              x: {
                title: {
                  display: true,
                  text: "Months (Year)",
                },
              },
              y: {
                title: {
                  display: true,
                  text: "Registrations",
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    const item = monthData[tooltipItem.dataIndex];
                    return `Count: ${item.count} - Month: ${item.month}`;
                  },
                },
              },
            },
          }}
        />
      </section>

      <div className="mt-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">
            Club Registration: Focus Areas
          </h2>

          <Bar
            data={{
              labels: clubFocusAreaData
                // .sort(() => Math.random() - 0.5)
                .map((item) => item._id.replace(/_/g, " ")),
              datasets: [
                {
                  data: clubFocusAreaData.map((item) => item.count),
                  backgroundColor: ["#42a5f5", "#66bb6a", "#ffca28"],
                },
              ],
            }}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Club Registration",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Focus Area",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </section>

        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">
            Club Registration: Spirit
          </h2>
          <Bar
            data={{
              labels: clubSpiritData.map((item) => {
                const label = item._id || "Unknown";
                return label.length > 20 ? `${label.slice(0, 20)}...` : label;
              }),
              datasets: [
                {
                  data: clubSpiritData.map((item) => item.count),
                  backgroundColor: [
                    "#ab47bc",
                    "#29b6f6",
                    "#78909c",
                    "#8d6e63",
                    "#ffa726",
                  ],
                },
              ],
            }}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Club Registration",
                  },
                  ticks: {
                    callback: function (label) {
                      if (label.length > 40) {
                        return label
                          .split("\n")
                          .map(
                            (line) =>
                              `<div class="whitespace-normal break-all">${line}</div>`
                          )
                          .join("\n");
                      }
                      return label;
                    },
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "3ZERO Club Spirit",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </section>
      </div>

      <div className="mt-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 h-100">
        <section className="mb-8 p-6 bg-white rounded-lg shadow-md h-100">
          <h2 className="text-2xl font-semibold mb-4">
            Club Member: Gender Ratio
          </h2>
          <div className="flex justify-center">
            <div style={{ width: "200px", height: "200px" }}>
              <Pie
                data={{
                  labels: ["Female", "Male", "Other"],
                  datasets: [
                    {
                      data: [
                        membersGenderData[0].memberFemaleTotal,
                        membersGenderData[0].memberMaleTotal,
                        membersGenderData[0].memberOtherTotal,
                      ],
                      backgroundColor: ["#ff69b4", "#1e90ff", "#9c27b0"],
                      hoverOffset: 4,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
            <div className="ml-2 text-sm space-y-2">
              <div className="flex items-center">
                <div
                  className="w-4 h-4 mr-1"
                  style={{ backgroundColor: "#ff69b4" }}
                ></div>{" "}
                Female: {membersGenderData[0].memberFemaleTotal}
              </div>
              <div className="flex items-center">
                <div
                  className="w-4 h-4 mr-1"
                  style={{ backgroundColor: "#1e90ff" }}
                ></div>{" "}
                Male: {membersGenderData[0].memberMaleTotal}
              </div>
              <div className="flex items-center">
                <div
                  className="w-4 h-4 mr-1"
                  style={{ backgroundColor: "#9c27b0" }}
                ></div>{" "}
                Other: {membersGenderData[0].memberOtherTotal}
              </div>
            </div>
          </div>
        </section>

        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">
            Club Member: Profession
          </h2>
          <Bar
            data={{
              labels: memberProfessionData.map((item) =>
                item._id.replace(/_/g, " ")
              ),
              datasets: [
                {
                  data: memberProfessionData.map((item) => item.count),
                  backgroundColor: [
                    "#42a5f5", // Blue
                    "#66bb6a", // Green
                    "#ffca28", // Yellow
                    "#ef5350", // Red
                    "#7e57c2", // Purple
                    "#26c6da", // Cyan
                  ],
                },
              ],
            }}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Member Count",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Profession",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </section>

        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">
            Club Member: Age Distribution
          </h2>
          <Bar
            data={{
              labels: ageDistributionData.map((item) => item.label),
              datasets: [
                {
                  data: ageDistributionData.map((item) => item.count),
                  backgroundColor: [
                    "#42a5f5", // Blue
                    "#66bb6a", // Green
                    "#ffca28", // Yellow
                    "#ef5350", // Red
                    "#7e57c2", // Purple
                  ],
                },
              ],
            }}
            options={{
              indexAxis: "y",
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "Member Count",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "Age Range",
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
