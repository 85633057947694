import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { get, put } from "../../../utils/fetchAPI";

Modal.setAppElement("#root");

const EditGeoStateModal = ({ isOpen, onClose, geoState, onGeoStateUpdate }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [countryShortCode, setCountryShortCode] = useState("");
  const [stateName, setStateName] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [stateCode, setStateCode] = useState("");

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await get("/geo-locations/countries");
        if (response.status === 200) {
          setCountries(response.data.data);

          if (geoState) {
            const countryId =
              typeof geoState.country === "string"
                ? geoState.country
                : geoState.country._id;
            setSelectedCountryId(countryId);
            setCountryShortCode(geoState.countryShortCode || "");
            setStateName(geoState.name);
            setStatus(geoState.status);
            setStateCode(geoState.code || "");
          }
        }
      } catch {
        toast.error("Error fetching countries.");
      }
    };
    fetchCountries();
  }, [geoState]);

  const handleSave = async () => {
    setLoading(true);

    const geoStateData = {
      country: selectedCountryId,
      countryShortCode,
      name: stateName,
      status,
      stateCode,
    };

    try {
      const response = await put(
        `/admin/geo-states-provinces/${geoState._id}`,
        geoStateData
      );
      if (response.status === 200) {
        toast.success("Geo state updated successfully!");
        onGeoStateUpdate(geoStateData);
        onClose();
      } else {
        toast.error(response.message || "Failed to update geo state.");
      }
    } catch (err) {
      toast.error("Error updating geo state. Please try again.");
      console.error("Error updating geo state", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-2"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-[500px] max-h-[720px] overflow-auto rounded-lg bg-white shadow-lg p-4">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-bold mb-2">Edit Geo State</h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-primary transition-all hover:bg-primary/10"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="mb-2 col-span-2">
            <label className="block text-xs font-medium text-primary">
              Country:
            </label>
            <select
              value={selectedCountryId}
              onChange={(e) => setSelectedCountryId(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country._id} value={country._id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2 col-span-2">
            <label className="block text-xs font-medium text-primary">
              Country Short Code:
            </label>
            <input
              type="text"
              value={countryShortCode}
              onChange={(e) => setCountryShortCode(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter country short code"
            />
          </div>

          <div className="mb-2 col-span-2">
            <label className="block text-xs font-medium text-primary">
              State Name:
            </label>
            <input
              type="text"
              value={stateName}
              onChange={(e) => setStateName(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter state name"
            />
          </div>

          <div className="mb-2 col-span-2">
            <label className="block text-xs font-medium text-primary">
              State Code:
            </label>
            <input
              type="text"
              value={stateCode}
              onChange={(e) => setStateCode(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter state code"
            />
          </div>

          <div className="mb-2 flex items-center col-span-2">
            <label className="block text-xs font-medium text-primary mr-2">
              Is Active:
            </label>
            <input
              type="checkbox"
              checked={status === true}
              onChange={(e) => setStatus(e.target.checked ? true : false)}
              className="h-4 w-4 text-primary"
            />
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-secondary rounded-md text-white text-xs"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="ml-2 px-4 py-2 bg-primary rounded-md text-white text-xs"
            disabled={loading}
          >
            {loading ? "Saving..." : "Update"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditGeoStateModal;
