import React, { useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { post } from "../../../utils/fetchAPI";

Modal.setAppElement("#root");

const CreateClubDeclarationModal = ({
  isOpen,
  onClose,
  onClubDeclarationCreate,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const clubDeclarationData = {
      name,
      description,
      isActive,
    };

    try {
      const response = await post(
        "/admin/club-declarations",
        clubDeclarationData
      );
      toast.success("Club declaration created successfully!");

      if (response.status === 200) {
        onClubDeclarationCreate(clubDeclarationData);
        onClose();
      } else {
        alert(response.message || "Failed to create club declaration.");
      }
    } catch (err) {
      toast.error("Error creating club declaration. Please try again.");
      console.error("Error creating club declaration", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-2"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-[500px] max-h-[720px] overflow-auto rounded-lg bg-white shadow-lg p-4">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-bold mb-2">Create Club Declaration</h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-primary transition-all hover:bg-primary/10"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>

        {/* Input Fields */}
        <div className="grid grid-cols-1 gap-4">
          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              Name:
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter club declaration name"
            />
          </div>

          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              Description:
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs h-20"
              placeholder="Enter description"
            />
          </div>

          <div className="mb-2 flex items-center">
            <label className="block text-xs font-medium text-primary mr-2">
              Is Active:
            </label>
            <input
              type="checkbox"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
              className="h-4 w-4 text-primary"
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-secondary rounded-md text-white text-xs"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="ml-2 px-4 py-2 bg-primary rounded-md text-white text-xs"
            disabled={loading}
          >
            {loading ? "Saving..." : "Create"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateClubDeclarationModal;
