import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import phoneCountryCodes from "../../components/phone-country-code/phone-country-code.json";
import { get, imageBaseURL, patch } from "../../utils/fetchAPI";

const getImageUrl = (url, defaultImage) => {
  return url ? `${imageBaseURL}/${url}` : defaultImage;
};

const EditAdmin = ({ onClose }) => {
  const { adminId } = useParams();
  const [userData, setUserData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await get(`/admin/admins/${adminId}`);
        if (response && response.status === 200 && response.data.success) {
          setUserData(response.data.data);
        } else {
          throw new Error(response.data.message || "Failed to retrieve data");
        }
      } catch (error) {
        console.error("Error fetching admin details:", error);
        toast.error(error.message || "Failed to retrieve admin details.");
      }
    };

    if (adminId) {
      fetchAdminDetails();
    }
  }, [adminId]);

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const response = await get("/admin/admins/get-details");
        const data = response.data;

        if (data.success) {
          setUserId(data.data._id);
        } else {
          throw new Error("Failed to retrieve profile details");
        }
      } catch (error) {
        console.error("Error fetching profile details:", error);
        toast.error(error.message || "Failed to retrieve profile details.");
      }
    };

    fetchProfileDetails();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await get("/admin/role/get-roles");
        if (response.data.success && response.data.data) {
          const roleOptions = response.data.data.map((role) => ({
            value: role._id,
            label: role.name,
          }));
          setRoles(roleOptions);
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        toast.error(
          "An error occurred while fetching roles. Please try again later."
        );
      }
    };

    fetchRoles();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevData) => {
      const newData = { ...prevData };
      if (name.includes(".")) {
        const [parentKey, childKey] = name.split(".");
        newData[parentKey] = { ...newData[parentKey], [childKey]: value };
      } else {
        newData[name] = value;
      }
      return newData;
    });
  };

  const handleRoleChange = (selectedOption) => {
    setUserData((prevData) => ({
      ...prevData,
      role: { _id: selectedOption.value },
    }));
  };

  const handlePhoneCodeChange = (selectedOption) => {
    setUserData((prevData) => ({
      ...prevData,
      phone: { ...prevData.phone, countryCode: selectedOption.value },
    }));
  };

  const handleSave = async () => {
    try {
      const response = await patch(`/admin/admins/${adminId}`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: {
          countryCode: userData.phone?.countryCode || "",
          number: userData.phone?.number || "",
        },
        department: userData.department,
        designation: userData.designation,
        // status: "active",
        username: userData.username,
        role: userData.role?._id,
      });

      const data = response.data;

      if (data.success) {
        toast.success("Profile updated successfully!");
        setIsEditing(false);
      } else if (
        data.statusCode === 400 &&
        data.error === "email already exists!"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error updating admin",
          text: "Email already exists!",
        });
      } else if (
        data.statusCode === 400 &&
        data.error === "username already exists!"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error updating admin",
          text: "Username already exists!",
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      const errorMsg =
        error.response?.data?.error ||
        error.message ||
        "Error updating profile.";
      Swal.fire({
        icon: "error",
        title: "Error updating admin",
        text: errorMsg,
      });
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await patch("/admin/admins/profile-image", formData);
        const data = response.data;

        if (data.success) {
          toast.success("Profile image updated!");
          setUserData((prev) => ({
            ...prev,
            profilePhoto: data.data.profilePhoto,
          }));
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error updating profile image",
          text: error.message,
        });
      }
    }
  };

  if (!userData) {
    return null;
  }

  const phoneCodeOptions = phoneCountryCodes.map((country) => ({
    value: country.value,
    label: country.label,
  }));

  const selectedCountryCode =
    phoneCodeOptions.find(
      (option) => option.value === userData.phone?.countryCode
    ) || null;

  const selectedRole =
    roles.find((role) => role.value === userData.role?._id) || null;

  return (
    <div className="flex items-center justify-center  bg-gray-100">
      <div className="w-full max-w-[900px] p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-xl font-bold mb-4 text-center">Edit Admin</h2>

        <button
          onClick={() => setIsEditing((prev) => !prev)}
          className="mb-4 px-4 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md hover:bg-blue-600"
        >
          {isEditing ? "Cancel Edit" : "Edit"}
        </button>

        {/* Profile Photo Section */}
        {/* <div className="flex flex-col items-center mb-4 relative">
          <label
            htmlFor="profileImageUpload"
            className="relative cursor-pointer"
          >
            {userData.profilePhoto ? (
              <img
                src={getImageUrl(userData.profilePhoto, "/default-profile.jpg")}
                alt="Profile"
                className="w-36 h-36 object-cover rounded-full"
              />
            ) : (
              <Avatar
                name={`${userData.firstName} ${userData.lastName}`}
                size="144"
                round={true}
              />
            )}
            <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 px-1 text-center text-xs font-semibold text-white bg-black bg-opacity-50 py-1 rounded-full">
              Change
            </span>
          </label>
          <input
            type="file"
            id="profileImageUpload"
            accept="image/*"
            onChange={handleProfileImageChange}
            className="hidden"
          />
        </div> */}

        {/* Admin Details Form */}
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              First Name:
            </label>
            <input
              type="text"
              name="firstName"
              value={userData.firstName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`mt-1 border rounded-md p-2 w-full text-sm ${
                !isEditing ? "bg-gray-200" : ""
              }`}
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Last Name:
            </label>
            <input
              type="text"
              name="lastName"
              value={userData.lastName}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`mt-1 border rounded-md p-2 w-full text-sm ${
                !isEditing ? "bg-gray-200" : ""
              }`}
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Email:
            </label>
            <input
              type="text"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              disabled
              className={`mt-1 border rounded-md p-2 w-full text-sm ${
                !isEditing ? "bg-gray-300" : "bg-gray-300"
              }`}
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Username:
            </label>
            <input
              type="text"
              name="username"
              value={userData.username}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`mt-1 border rounded-md p-2 w-full text-sm ${
                !isEditing ? "bg-gray-200" : ""
              }`}
            />
          </div>

          {/* Phone Section with Two Columns */}
          {/* <div className="mb-1 col-span-2">
            <label className="block text-sm font-medium text-primary mb-1">
              Phone:
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Select
                  value={selectedCountryCode}
                  options={phoneCodeOptions}
                  onChange={handlePhoneCodeChange}
                  isDisabled={!isEditing}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #d1d5db",
                      borderRadius: "0.375rem",
                      height: "2.5rem",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#6b7280",
                      },
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="phone.number"
                  value={userData.phone?.number || ""}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  className={`border rounded-md p-2 w-full text-sm ${
                    !isEditing ? "bg-gray-200" : ""
                  }`}
                />
              </div>
            </div>
          </div> */}

          {/* Phone Section in a Single Field */}
          <div className="mb-2">
            <label className="block text-sm font-medium text-primary mb-1">
              Phone:
            </label>
            <div className="flex">
              <div className="flex items-center border rounded-l-md p-1 bg-gray-50">
                <Select
                  value={selectedCountryCode}
                  options={phoneCodeOptions}
                  onChange={handlePhoneCodeChange}
                  isDisabled={!isEditing}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "1.75rem",
                      fontSize: "0.875rem",
                      border: "none",
                      boxShadow: "none",
                      minWidth: "110px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: "0 4px",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0 4px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "200px",
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <input
                type="text"
                name="phone.number"
                value={userData.phone?.number || ""}
                onChange={handleInputChange}
                disabled={!isEditing}
                placeholder="Phone Number"
                className={`border rounded-r-md p-1 w-full text-sm ${
                  !isEditing ? "bg-gray-200" : ""
                }`}
                style={{ fontSize: "0.875rem", height: "2.4rem" }}
              />
            </div>
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Department:
            </label>
            <input
              type="text"
              name="department"
              value={userData.department}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`mt-1 border rounded-md p-2 w-full text-sm ${
                !isEditing ? "bg-gray-200" : ""
              }`}
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Designation:
            </label>
            <input
              type="text"
              name="designation"
              value={userData.designation || ""}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`mt-1 border rounded-md p-2 w-full text-sm ${
                !isEditing ? "bg-gray-200" : ""
              }`}
            />
          </div>

          {userId !== adminId && (
            <div className="mb-2">
              <label className="block text-sm font-medium text-primary">
                Role:
              </label>
              <Select
                value={selectedRole}
                options={roles}
                onChange={handleRoleChange}
                isDisabled={!isEditing}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #d1d5db",
                    borderRadius: "0.375rem",
                    boxShadow: "none",
                    "&:hover": {
                      borderColor: "#6b7280",
                    },
                  }),
                }}
              />
            </div>
          )}

          {isEditing && (
            <div className="col-span-2 mt-4">
              <button
                onClick={handleSave}
                className="px-4 py-2 text-sm font-semibold text-white bg-green-500 rounded-md hover:bg-green-600"
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;
