import { DateTime } from "luxon";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del } from "../../../utils/fetchAPI";
import CreateFocusAreaModal from "./CreateFocusAreaModal";
import EditFocusAreaModal from "./EditFocusAreaModal";

const FocusAreaTable = ({
  focusAreas: initialFocusAreas,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedFocusArea, setSelectedFocusArea] = useState(null);
  const [focusAreas, setFocusAreas] = useState(initialFocusAreas);

  const navigate = useNavigate();

  const formatDateTime = (dateString) => {
    const dt = DateTime.fromISO(dateString, { zone: "utc" }).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    return dt;
  };

  const handleFocusAreaCreate = (newFocusArea) => {
    setFocusAreas((prevFocusAreas) => [newFocusArea, ...prevFocusAreas]);
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Description",
      selector: (row) => row.description || "No description provided",
      sortable: false,
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      wrap: true,
      width: "170px",
    },
    {
      name: "Updated At",
      selector: (row) => formatDateTime(row.updatedAt),
      sortable: true,
      wrap: true,
      width: "170px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.isActive ? "bg-green-200" : "bg-red-200"
            }`}
          >
            {row.isActive ? "Active" : "Inactive"}
          </h2>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          {/* <button
            onClick={() => {
              setSelectedFocusArea(row);
              setIsEditModalOpen(true);
            }}
            className="text-blue-500 hover:text-blue-700"
          >
            <FaEye className="text-xl" />
          </button> */}
          <button
            onClick={() => {
              setSelectedFocusArea(row);
              setIsEditModalOpen(true);
            }}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteFocusArea(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleFocusAreaUpdate = (updatedFocusArea) => {
    setFocusAreas((prevFocusAreas) =>
      prevFocusAreas.map((focusArea) =>
        focusArea._id === updatedFocusArea._id
          ? { ...focusArea, ...updatedFocusArea }
          : focusArea
      )
    );
  };

  const handleDeleteFocusArea = (focusAreaId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        del(`admin/club-focus-areas/${focusAreaId}`)
          .then(() => {
            setFocusAreas((prevFocusAreas) =>
              prevFocusAreas.filter(
                (focusArea) => focusArea._id !== focusAreaId
              )
            );
            Swal.fire(
              "Deleted!",
              "Focus area deleted successfully.",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error deleting focus area", error);
            Swal.fire("Error!", "Failed to delete focus area.", "error");
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={focusAreas}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Focus Areas</h1>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
              aria-label="Create focus area"
            >
              <FaPlus className="mr-2" /> Create Focus Area
            </button>
          </div>
        }
      />
      <EditFocusAreaModal
        focusArea={selectedFocusArea}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onFocusAreaUpdate={handleFocusAreaUpdate}
      />
      <CreateFocusAreaModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onFocusAreaCreate={handleFocusAreaCreate}
      />
    </div>
  );
};

export default FocusAreaTable;
