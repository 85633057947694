import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ConfirmStatusModal = ({
  isOpen,
  onClose,
  onConfirm,
  currentStatus,
  newStatusOptions,
  setNewStatus,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-2"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="bg-white rounded-lg shadow-lg p-4 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Confirm Status Change</h2>
        <p className="mb-4">
          Current Status: <b>{currentStatus}</b>
        </p>
        <p>Select the new status:</p>
        <select
          onChange={(e) => setNewStatus(e.target.value)}
          className="w-full mt-2 p-2 border rounded-md"
        >
          <option value="">Select status</option>
          {newStatusOptions.map((status) => (
            <option key={status} value={status}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </option>
          ))}
        </select>

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-primary text-white px-4 py-2 rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmStatusModal;
