import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-5xl font-bold text-gray-800 mb-4">404 - Not Found</h1>
      <p className="text-lg text-gray-600 mb-6">
        The page you are looking for doesn't exist.
      </p>
      <div className="flex space-x-4">
        <button
          onClick={handleGoHome}
          className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition"
        >
          Go to Home
        </button>
        <button
          onClick={handleGoBack}
          className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark transition"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
