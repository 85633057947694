import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing icons
import { useNavigate, useSearchParams } from "react-router-dom";
import { post } from "../../../utils/fetchAPI";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false); // State for new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  // Token validation logic
  useEffect(() => {
    const isValidTokenFormat = /^[a-f0-9]{64}$/.test(token);
    setIsTokenValid(isValidTokenFormat);

    if (!isValidTokenFormat) {
      setResetMessage("Link is not verified");
    }
  }, [token]);

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setResetMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await post("/admin/admins/reset-password", {
        token,
        password: newPassword,
      });
      if (response.data && response.data.success) {
        setResetMessage("Password reset successful. Redirecting to login...");
        setTimeout(() => navigate("/login"), 2000);
      } else {
        setResetMessage("Failed to reset password. Try again.");
      }
    } catch (error) {
      setResetMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative px-6 py-3 mx-auto w-full sm:w-96 text-center">
        <span className="text-2xl text-lightSecondary">Reset Password</span>
        <div className="mt-4 bg-white shadow-md rounded-lg text-left">
          <div className="h-2 bg-primary rounded-t-md"></div>
          <div className="border px-8 py-6">
            {resetMessage && (
              <div className="flex justify-between items-center">
                <p className={`text-red-500 font-bold text-center`}>
                  {resetMessage}
                </p>
                <button
                  onClick={() => setResetMessage("")}
                  className="text-red-500"
                >
                  ✖
                </button>
              </div>
            )}
            {isTokenValid ? (
              <>
                <label
                  className="label block font-semibold"
                  htmlFor="newPassword"
                >
                  New Password
                </label>
                <div className="relative">
                  <input
                    className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    className="absolute right-3 top-[60%] transform -translate-y-1/2 flex items-center"
                  >
                    {showNewPassword ? (
                      <FaEyeSlash className="text-primary" />
                    ) : (
                      <FaEye className="text-primary" />
                    )}
                  </button>
                </div>
                <label
                  className="label mt-3 block font-semibold"
                  htmlFor="confirmPassword"
                >
                  Confirm Password
                </label>
                <div className="relative">
                  <input
                    className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="absolute right-3 top-[60%] transform -translate-y-1/2 flex items-center"
                  >
                    {showConfirmPassword ? (
                      <FaEyeSlash className="text-primary" />
                    ) : (
                      <FaEye className="text-primary" />
                    )}
                  </button>
                </div>
                <div className="flex items-center mt-4">
                  <button
                    onClick={handleResetPassword}
                    className="px-4 py-2 bg-secondary text-white rounded-md"
                  >
                    Reset Password
                  </button>
                </div>
              </>
            ) : null}
            {!isTokenValid && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => navigate("/login")}
                  className="px-4 py-2 bg-gray-300 text-black rounded-md"
                >
                  Back
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
