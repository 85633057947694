import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditorComponent from "../../components/editor/JoditEditorComponent";
import { get, post } from "../../utils/fetchAPI";

const CreateBulletin = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [form, setForm] = useState({
    title: "",
    content: "",
    banners: [],
    category: "",
  });

  const [imagePreviews, setImagePreviews] = useState({
    bannerPreviews: [],
  });

  const [categories, setCategories] = useState([]);
  const [isBannerSelected, setIsBannerSelected] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await get("/admin/clubs/focus-areas");
        if (response.status === 200) setCategories(response.data.data);
      } catch {
        toast.error("Error fetching categories.");
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    const newBanners = [];
    const newBannerPreviews = [];

    files.forEach((file) => {
      newBanners.push(file);
      const url = URL.createObjectURL(file);
      newBannerPreviews.push(url);
    });

    setForm((prevState) => ({
      ...prevState,
      banners: [...prevState.banners, ...newBanners],
    }));

    setImagePreviews((prevState) => ({
      ...prevState,
      bannerPreviews: [...prevState.bannerPreviews, ...newBannerPreviews],
    }));

    setIsBannerSelected(true);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const removeBannerImage = (index) => {
    setForm((prevState) => {
      const newBanners = [...prevState.banners];
      newBanners.splice(index, 1);
      setIsBannerSelected(newBanners.length > 0);
      return {
        ...prevState,
        banners: newBanners,
      };
    });

    setImagePreviews((prevState) => {
      const newBannerPreviews = [...prevState.bannerPreviews];
      newBannerPreviews.splice(index, 1);
      return {
        ...prevState,
        bannerPreviews: newBannerPreviews,
      };
    });
  };

  const handleContentChange = (content) => {
    setForm((prevState) => ({
      ...prevState,
      content,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.content.trim()) {
      toast.error("Content is required.");
      return;
    }

    if (!isBannerSelected) {
      toast.error("At least one banner image is required.");
      return;
    }

    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key === "banners") {
        form[key].forEach((file) => formData.append("banners", file));
      } else {
        formData.append(key, form[key]);
      }
    });

    try {
      await post("/admin/bulletins", formData);
      toast.success("Bulletin created successfully!");
      setForm({
        title: "",
        content: "",
        banners: [],
        category: "",
      });
      setImagePreviews({
        bannerPreviews: [],
      });
      setIsBannerSelected(false);
      navigate(`/bulletins/`);
    } catch (error) {
      toast.error("Error creating bulletin. Please try again.");
      console.error("Error during bulletin creation:", error);
    }
  };

  return (
    <section className="p-1">
      <div className="py-1">
        <div className="bg-white p-5 rounded shadow-md">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="title"
                value={form.title}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Content
              </label>
              <div className="h-auto">
                <JoditEditorComponent
                  content={form.content}
                  onChange={handleContentChange}
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Category <span className="text-red-500">*</span>
              </label>
              <select
                name="category"
                value={form.category}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded"
                required
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Add Banner Images <span className="text-red-500">*</span>
              </label>
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded"
                multiple
              />
            </div>

            <div className="mb-4">
              <div className="flex flex-wrap">
                {imagePreviews.bannerPreviews.map((preview, index) => (
                  <div key={index} className="relative mr-2 mb-2">
                    <img
                      src={preview}
                      alt={`Banner Preview ${index}`}
                      className="h-32 w-auto object-cover rounded"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                      onClick={() => removeBannerImage(index)}
                    >
                      <span className="text-xs">X</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={`mt-4 py-2 text-white bg-blue-600 rounded`}
                style={{ width: "200px" }}
              >
                Create Bulletin
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CreateBulletin;
