import { DateTime } from "luxon";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del } from "../../../utils/fetchAPI";
import CreateGeoCityModal from "./CreateGeoCityModal";
import EditGeoCityModal from "./EditGeoCityModal";

const GeoCitiesTable = ({
  geoCities: initialGeoCities,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  search,
  setSearch,
  handleSearch,
  status,
  setStatus,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedGeoCity, setSelectedGeoCity] = useState(null);
  const [geoCities, setGeoCities] = useState(initialGeoCities);
  //   const [countryNames, setCountryNames] = useState({});
  //   const [stateNames, setStateNames] = useState({});

  const navigate = useNavigate();

  // Fetch country and state names when geoCities changes
  //   useEffect(() => {
  //     const fetchCountryAndStateNames = async () => {
  //       const countryNamesMap = {};
  //       const stateNamesMap = {};

  //       for (const geoCity of initialGeoCities) {
  //         // Fetch country names
  //         if (!countryNamesMap[geoCity.country]) {
  //           try {
  //             const response = await get(
  //               `admin/geo-countries/${geoCity.country}`
  //             );
  //             console.log(response.data.data.name);
  //             countryNamesMap[geoCity.country] = response.data.data.name; // Store country name
  //           } catch (error) {
  //             console.error("Error fetching country:", error);
  //           }
  //         }

  //         // Fetch state names
  //         if (!stateNamesMap[geoCity.country]) {
  //           try {
  //             const response = await get(
  //               `geo-locations/states?countryId=${geoCity.country}`
  //             );
  //             stateNamesMap[geoCity.country] = response.data.data; // Store state names (assumed to be an array)
  //           } catch (error) {
  //             console.error("Error fetching states:", error);
  //           }
  //         }
  //       }

  //       setCountryNames(countryNamesMap);
  //       setStateNames(stateNamesMap);
  //     };

  //     fetchCountryAndStateNames();
  //   }, [initialGeoCities]);

  const formatDateTime = (dateString) => {
    const dt = DateTime.fromISO(dateString, { zone: "utc" }).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    return dt;
  };

  const handleGeoCityCreate = (newGeoCity) => {
    setGeoCities((prevGeoCities) => [newGeoCity, ...prevGeoCities]);
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "City",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    // {
    //   name: "Country",
    //   selector: (row) => countryNames[row.country] || "Loading...",
    //   sortable: true,
    //   wrap: true,
    // },
    {
      name: "Country",
      selector: (row) => row.country?.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Country Short Code",
      selector: (row) => row.countryShortCode,
      sortable: false,
      wrap: true,
    },
    // {
    //   name: "State",
    //   selector: (row) => {
    //     const stateArray = stateNames[row.country];
    //     const state = stateArray
    //       ? stateArray.find((state) => state.id === row.stateId)
    //       : null;
    //     return state ? state.name : "Loading...";
    //   },
    //   sortable: true,
    //   wrap: true,
    // },
    {
      name: "Latitude",
      selector: (row) => row.latitude,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Longitude",
      selector: (row) => row.longitude,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    // {
    //   name: "Created At",
    //   selector: (row) => formatDateTime(row.createdAt),
    //   sortable: true,
    //   wrap: true,
    //   width: "170px",
    // },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.status === true ? "bg-green-200" : "bg-red-200"
            }`}
          >
            {row.status === true ? "Active" : "Inactive"}
          </h2>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => {
              setSelectedGeoCity(row);
              setIsEditModalOpen(true);
            }}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteGeoCity(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleGeoCityUpdate = (updatedGeoCity) => {
    setGeoCities((prevGeoCities) =>
      prevGeoCities.map((geoCity) =>
        geoCity._id === updatedGeoCity._id
          ? { ...geoCity, ...updatedGeoCity }
          : geoCity
      )
    );
  };

  const handleDeleteGeoCity = (geoCityId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        del(`admin/geo-cities/${geoCityId}`)
          .then(() => {
            setGeoCities((prevGeoCities) =>
              prevGeoCities.filter((geoCity) => geoCity._id !== geoCityId)
            );
            Swal.fire("Deleted!", "Geo city deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting geo city", error);
            Swal.fire("Error!", "Failed to delete geo city.", "error");
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={geoCities}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Geo Cities</h1>
            <div className="flex items-center space-x-2">
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by status"
              >
                <option value="">Select Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>

              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search city..."
                className="p-2 border rounded"
                aria-label="Search bulletins"
              />
              <button
                onClick={handleSearch}
                className="ml-2 p-2 bg-blue-500 text-white rounded"
              >
                Search
              </button>
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
                aria-label="Create geo city"
              >
                <FaPlus className="mr-2" /> Create Geo City
              </button>
            </div>
          </div>
        }
      />
      <EditGeoCityModal
        geoCity={selectedGeoCity}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onGeoCityUpdate={handleGeoCityUpdate}
      />
      <CreateGeoCityModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onGeoCityCreate={handleGeoCityCreate}
      />
    </div>
  );
};

export default GeoCitiesTable;
