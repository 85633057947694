import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import phoneCountryCodes from "../../components/phone-country-code/phone-country-code.json";
import { get, post } from "../../utils/fetchAPI";

const CreateAdmin = () => {
  const navigate = useNavigate();

  const [adminData, setAdminData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: {
      // countryCode: { value: "+880", label: "Bangladesh (+880)" },
      countryCode: "",
      number: "",
    },
    department: "",
    designation: "",
    role: "",
  });

  const [emailError, setEmailError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]);

  // Fetch roles on component mount
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await get("/admin/role/get-roles");

        console.log("Roles API Response:", response);

        if (
          response.data.success &&
          response.data.statusCode === 200 &&
          response.data.data
        ) {
          const roleOptions = response.data.data.map((role) => ({
            value: role._id,
            label: role.name,
          }));
          setRoles(roleOptions);
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        Swal.fire({
          icon: "error",
          title: "Failed to fetch roles",
          text: "An error occurred while fetching roles. Please try again later.",
        });
      }
    };

    fetchRoles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "email") {
      setEmailError("");
    }
  };

  const handlePhoneChange = (field, value) => {
    setAdminData((prevData) => ({
      ...prevData,
      phone: {
        ...(prevData.phone || {}),
        [field]: value,
      },
    }));
  };

  const handlePhoneCodeChange = (selectedOption) => {
    handlePhoneChange("countryCode", selectedOption);
  };

  const handleRoleChange = (selectedOption) => {
    setAdminData((prevData) => ({
      ...prevData,
      role: selectedOption.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setEmailError("");

    const adjustedAdminData = {
      ...adminData,
      phone: {
        countryCode: adminData.phone.countryCode.value,
        number: adminData.phone.number,
      },
    };

    try {
      const response = await post("/admin/admins", adjustedAdminData);

      if (
        response.status === 400 &&
        response.error === "email already exists!"
      ) {
        setEmailError("Email already exists!");

        Swal.fire({
          icon: "error",
          title: "Error creating admin",
          text: "Email already exists!",
        });
        return;
      }

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      toast.success("Admin created successfully!");

      navigate("/admins");

      setAdminData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: {
          countryCode: { value: "+880", label: "Bangladesh (+880)" },
          number: "",
        },
        department: "",
        designation: "",
        role: "",
      });
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.error === "email already exists!"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error creating admin",
          text: "Email already exists!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error creating admin",
          text: error.message,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-screen-md mx-auto bg-white rounded-lg shadow-md p-6">
      <h2 className="text-lg font-bold mb-4">Create Admin</h2>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <label>
            First Name:
            <input
              type="text"
              name="firstName"
              value={adminData.firstName}
              onChange={handleChange}
              required
              className="border rounded p-2 w-full text-sm h-10"
            />
          </label>

          <label>
            Last Name:
            <input
              type="text"
              name="lastName"
              value={adminData.lastName}
              onChange={handleChange}
              required
              className="border rounded p-2 w-full text-sm h-10"
            />
          </label>

          <label>
            Email:
            <input
              type="email"
              name="email"
              value={adminData.email}
              onChange={handleChange}
              required
              className="border rounded p-2 w-full text-sm h-10"
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1">{emailError}</p>
            )}
          </label>

          <label>
            Password:
            <div className="relative mb-4">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={adminData.password}
                onChange={handleChange}
                required
                className="border rounded p-2 w-full text-sm h-10"
              />
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </button>
            </div>
          </label>

          {/* <label>
            Phone Code:
            <Select
              value={adminData.phone.countryCode}
              onChange={handlePhoneCodeChange}
              options={phoneCountryCodes.map((country) => ({
                value: country.value,
                label: country.label,
              }))}
              className="mb-4"
            />
          </label>

          <label>
            Phone Number:
            <input
              type="text"
              value={adminData.phone.number}
              onChange={(e) => handlePhoneChange("number", e.target.value)}
              required
              className="border rounded p-2 w-full text-sm h-10"
            />
          </label> */}

          {/* Phone Section in a Single Field */}
          <div className="mb-2">
            <label className="block text-sm font-medium text-primary mb-1">
              Phone:
            </label>
            <div className="flex">
              <div className="flex items-center border rounded-l-md p-1 bg-gray-50">
                <Select
                  value={adminData.phone.countryCode}
                  options={phoneCountryCodes.map((country) => ({
                    value: country.value,
                    label: country.label,
                  }))}
                  onChange={handlePhoneCodeChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "1.75rem",
                      fontSize: "0.875rem",
                      border: "none",
                      boxShadow: "none",
                      minWidth: "110px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: "0 4px",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0 4px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "200px",
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <input
                type="text"
                name="phone.number"
                value={adminData.phone.number}
                onChange={(e) => handlePhoneChange("number", e.target.value)}
                required
                placeholder="Phone Number"
                className={`border rounded-r-md p-1 w-full text-sm bg-gray-200}`}
                style={{ fontSize: "0.875rem", height: "2.4rem" }}
              />
            </div>
          </div>

          <label>
            Department:
            <input
              type="text"
              name="department"
              value={adminData.department}
              onChange={handleChange}
              required
              className="border rounded p-2 w-full text-sm h-10"
            />
          </label>

          <label>
            Designation:
            <input
              type="text"
              name="designation"
              value={adminData.designation}
              onChange={handleChange}
              required
              className="border rounded p-2 w-full text-sm h-10"
            />
          </label>

          <label>
            Role:
            <Select
              value={roles.find((role) => role.value === adminData.role)}
              onChange={handleRoleChange}
              options={roles}
              className="mb-4"
            />
          </label>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="bg-primary text-white rounded p-2 text-sm h-10"
        >
          {isSubmitting ? "Creating..." : "Create Admin"}
        </button>
      </form>
    </div>
  );
};

export default CreateAdmin;
