// EditStatusModal.js
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { get } from "../../utils/fetchAPI";

Modal.setAppElement("#root");

const EditStatusModal = ({ user, isOpen, onClose, onStatusUpdate }) => {
  const [status, setStatus] = useState("");
  // const [loading, setLoading] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");

  useEffect(() => {
    if (user) {
      setStatus(user.status);
      fetchCountryName(user.country);
      fetchCityName(user.city);
      fetchStateName(user.state);
    }
  }, [user]);

  const fetchCountryName = async (countryId) => {
    try {
      const response = await get(`/admin/geo-countries/${countryId}`);
      setCountryName(response.data?.data?.name);
    } catch (error) {
      console.error("Error fetching country name", error);
      setCountryName("N/A");
    }
  };

  const fetchCityName = async (cityId) => {
    try {
      const response = await get(`/admin/geo-cities/${cityId}`);
      setCityName(response.data?.data?.name);
    } catch (error) {
      console.error("Error fetching city name", error);
      setCityName("N/A");
    }
  };

  const fetchStateName = async (stateId) => {
    try {
      const response = await get(`/admin/geo-states-provinces/${stateId}`);
      setStateName(response.data?.data?.name);
    } catch (error) {
      console.error("Error fetching state name", error);
      setStateName("N/A");
    }
  };

  // const handleSave = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await put(`/admin/users/update-status/${user._id}`, {
  //       status,
  //     });
  //     console.log(response);
  //     if (response?.status === 200) {
  //       onStatusUpdate(user._id, status);
  //       onClose();
  //     }
  //   } catch (err) {
  //     console.error("Error updating status", err.response?.data || err.message);
  //     alert(
  //       err.response?.data?.message ||
  //         "Failed to update status. Please try again."
  //     );
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleStatusChange = () => {
  //   setStatus((prevStatus) =>
  //     prevStatus === "active" ? "inactive" : "active"
  //   );
  // };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-2"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-[1200px] max-h-[720px] overflow-auto rounded-lg bg-white shadow-lg p-4">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-bold mb-2">User Details</h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-primary transition-all hover:bg-primary/10"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>
        {user && (
          <div>
            <div className="grid grid-cols-4 gap-4">
              {/* User Information */}
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Full Name:
                </label>
                <input
                  type="text"
                  value={`${user.firstName} ${user.lastName}`}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Email:
                </label>
                <input
                  type="text"
                  value={user.email || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Phone/Mobile:
                </label>
                <input
                  type="text"
                  value={
                    user && user.phone
                      ? user.phone.countryCode
                        ? `${user.phone.countryCode}${user.phone.number}`
                        : user.phone.number
                      : "N/A"
                  }
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>

              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Date of Birth:
                </label>
                <input
                  type="text"
                  value={new Date(user.dateOfBirth).toLocaleDateString(
                    "en-GB",
                    {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Gender:
                </label>
                <input
                  type="text"
                  value={user.gender || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Education:
                </label>
                <input
                  type="text"
                  value={user.lastEducation || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Profession:
                </label>
                <input
                  type="text"
                  value={user.lastProfession || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Designation:
                </label>
                <input
                  type="text"
                  value={user.lastDesignation || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Organization:
                </label>
                <input
                  type="text"
                  value={user.lastOrganization || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-4">
                <label className="block text-xs font-medium text-primary">
                  Address:
                </label>
                <input
                  type="text"
                  value={user.address || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  City:
                </label>
                <input
                  type="text"
                  value={cityName || "Loading..."}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  State:
                </label>
                <input
                  type="text"
                  value={stateName || "Loading..."}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Country:
                </label>
                <input
                  type="text"
                  value={countryName || "Loading..."}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-1">
                <label className="block text-xs font-medium text-primary">
                  Zip:
                </label>
                <input
                  type="text"
                  value={user.zip || "N/A"}
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black"
                />
              </div>
              <div className="mb-2 col-span-4">
                <label className="block text-xs font-medium text-primary">
                  Interest Reasons:
                </label>
                <textarea
                  value={
                    Array.isArray(user.interestReasons) &&
                    user.interestReasons.length > 0
                      ? user.interestReasons.join(", ")
                      : "N/A"
                  }
                  disabled
                  className="mt-1 border rounded-md p-1 w-full bg-gray-100 text-xs text-black h-20"
                />
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-secondary rounded-md text-white text-xs"
              >
                Close
              </button>
              {/* Uncomment the save button once the save functionality is implemented */}
              {/* <button
                onClick={handleSave}
                className="ml-2 px-4 py-2 bg-primary rounded-md text-white text-xs"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button> */}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditStatusModal;
