import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import phoneCountryCodes from "../../components/phone-country-code/phone-country-code.json";
import { get, getWithParams, patch } from "../../utils/fetchAPI";
import ConfirmStatusModal from "./ConfirmStatusModal";

const ClubDetail = () => {
  const { id } = useParams();
  const [clubData, setClubData] = useState(null);
  const [focusAreas, setFocusAreas] = useState([]);
  const [selectedFocusArea, setSelectedFocusArea] = useState(null);
  const [declarations, setDeclarations] = useState([]);
  const [selectedDeclarationIds, setSelectedDeclarationIds] = useState([]);
  const [clubStatus, setClubStatus] = useState("pending");
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [newStatusOptions, setNewStatusOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [states, setStates] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("");

  const fetchClubData = async () => {
    try {
      const response = await get(`/admin/clubs/${id}`);
      if (response.status === 200) {
        const data = response.data.data;
        setClubData(data);
        setSelectedFocusArea(data.clubFocusArea._id);
        setClubStatus(data.clubStatus || "pending");
        setSelectedDeclarationIds(data.declarations.map((dec) => dec._id));
        setSelectedCountryId(data.country._id);
        setSelectedStateId(data.state._id);
        setSelectedCityId(data.city._id);
      }
    } catch {
      toast.error("Error fetching club data.");
    }
  };

  useEffect(() => {
    fetchClubData();
  }, [id]);

  const fetchCountries = async () => {
    try {
      const response = await get("/geo-locations/countries");
      if (response.status === 200) setCountries(response.data.data);
    } catch {
      toast.error("Error fetching countries.");
    }
  };

  const fetchStates = async (countryId) => {
    try {
      console.log(`countryId`, selectedCountryId);
      const response = await getWithParams(`/geo-locations/states`, {
        countryId,
      });
      console.log(`state_response`, response);
      if (response.status === 200) setStates(response.data.data);
    } catch {
      toast.error("Error fetching states.");
    }
  };

  const fetchCities = async (countryId) => {
    try {
      const response = await getWithParams(`/geo-locations/cities`, {
        countryId,
      });
      if (response.status === 200) setCities(response.data.data);
    } catch {
      toast.error("Error fetching cities.");
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (selectedCountryId) {
      fetchStates(selectedCountryId);
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedCountryId) {
      fetchCities(selectedCountryId);
    }
  }, [selectedCountryId]);

  const fetchFocusAreas = async () => {
    try {
      const response = await get("/admin/clubs/focus-areas");
      if (response.status === 200) setFocusAreas(response.data.data);
    } catch {
      toast.error("Error fetching focus areas.");
    }
  };

  const fetchDeclarations = async () => {
    try {
      const response = await get("/admin/clubs/club-declarations");
      if (response.status === 200) setDeclarations(response.data.data);
    } catch {
      toast.error("Error fetching declarations.");
    }
  };

  useEffect(() => {
    fetchFocusAreas();
    fetchDeclarations();
  }, []);

  const handleDeclarationChange = (e, id) => {
    setSelectedDeclarationIds((prev) =>
      e.target.checked ? [...prev, id] : prev.filter((d) => d !== id)
    );
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        clubFocusArea: selectedFocusArea,
        clubStatus,
        declarations: selectedDeclarationIds,
        country: selectedCountryId,
        state: selectedStateId,
        city: selectedCityId,
        phone: clubData.phone,
        name: clubData.name,
      };
      const response = await patch(`/admin/clubs/${clubData._id}`, updatedData);
      if (response.status === 200) {
        setClubData(response.data.data);
        toast.success("Club details updated successfully!");
        setIsEditing(false);
      }
    } catch {
      toast.error("Error saving club details.");
    }
  };

  const handleStatusClick = () => {
    let options = [];
    if (clubStatus === "pending") {
      options = ["processing", "approved", "rejected"];
    } else if (clubStatus === "processing") {
      options = ["approved", "rejected"];
    } else if (clubStatus === "approved") {
      options = ["rejected", "processing"];
    } else if (clubStatus === "rejected") {
      options = ["approved", "processing"];
    }
    setNewStatusOptions(options);
    setIsModalOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    try {
      const response = await patch(`/admin/clubs/${clubData._id}`, {
        clubStatus: newStatus,
      });
      if (response.status === 200) {
        setClubStatus(newStatus);
        toast.success("Club status updated!");
        setIsModalOpen(false);
      }
    } catch {
      toast.error("Error updating club status.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const Select = ({ label, value, onChange, children, disabled }) => (
    <div className="mb-2">
      <label className="text-sm font-medium text-primary">{label}:</label>
      <select
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`w-full mt-1 p-1 border rounded-md text-sm ${
          disabled ? "bg-gray-100" : "bg-white"
        }`}
      >
        {children}
      </select>
    </div>
  );

  if (!clubData) return <div>Loading...</div>;

  return (
    <div className="p-4 rounded-lg bg-white shadow-lg">
      <h2 className="text-lg font-bold mb-2">Club Details</h2>
      <div className="flex justify-end mb-4">
        {isEditing ? (
          <>
            <button
              onClick={handleSaveChanges}
              className="px-4 py-2 bg-green-500 text-white text-sm font-semibold rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={handleEditToggle}
              className="px-4 py-2 bg-gray-500 text-white text-sm font-semibold rounded-md"
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={handleEditToggle}
            className="px-4 py-2 bg-blue-500 text-white text-sm font-semibold rounded-md"
          >
            Edit
          </button>
        )}
      </div>

      <div className="grid grid-cols-4 gap-2 mb-4">
        {/* <Input label="Club Name" value={clubData.name} disabled={!isEditing} /> */}
        <Input
          label="Club Name"
          value={clubData.name}
          onChange={(e) =>
            setClubData({
              ...clubData,
              name: e.target.value,
            })
          }
          disabled={!isEditing}
        />

        <Select
          label="Focus Area"
          value={selectedFocusArea}
          onChange={(e) => setSelectedFocusArea(e.target.value)}
          disabled={!isEditing}
        >
          {focusAreas.map((area) => (
            <option key={area._id} value={area._id}>
              {area.name}
            </option>
          ))}
        </Select>
        {/* <Input
          label="Phone"
          value={`${clubData.phone.countryCode}${clubData.phone.number}`}
          disabled={!isEditing}
        /> */}

        <div className="grid grid-cols-2 gap-2">
          <Select
            label="Phone Code"
            value={clubData.phone?.countryCode || ""}
            onChange={(e) =>
              setClubData({
                ...clubData,
                phone: {
                  ...(clubData.phone || {}),
                  countryCode: e.target.value,
                },
              })
            }
            disabled={!isEditing}
            searchable={isEditing}
          >
            {phoneCountryCodes.map((country) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </Select>

          <Input
            label="Phone Number"
            value={clubData.phone?.number || ""}
            onChange={(e) =>
              setClubData({
                ...clubData,
                phone: {
                  ...(clubData.phone || {}),
                  number: e.target.value,
                },
              })
            }
            disabled={!isEditing}
          />
        </div>

        <Input label="Email" value={clubData.email} disabled={!isEditing} />
        <Select label="Gender" value={clubData.clubMemberGender} disabled>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="mixed">Mixed</option>
        </Select>
        <Input
          label="How Did You Hear"
          value={clubData.howDidYouHear}
          disabled
        />
        {clubData.howDidYouHear === "Other" && (
          <Input label="Other" value={clubData.howDidYouHearOther} disabled />
        )}
        <Input label="Address" value={clubData.address} disabled={!isEditing} />
        {/* <Input label="Country" value={clubData.country.name} disabled /> */}
        <Select
          label="Country"
          value={selectedCountryId}
          onChange={(e) => setSelectedCountryId(e.target.value)}
          disabled={!isEditing}
        >
          {countries.map((country) => (
            <option key={country._id} value={country._id}>
              {country.name}
            </option>
          ))}
        </Select>
        {/* <Input label="State/Division" value={clubData.state.name} disabled /> */}
        <Select
          label="State/Division"
          value={selectedStateId}
          onChange={(e) => setSelectedStateId(e.target.value)}
          disabled={!isEditing}
        >
          {states.map((state) => (
            <option key={state._id} value={state._id}>
              {state.name}
            </option>
          ))}
        </Select>
        {/* <Input label="City/District" value={clubData.city.name} disabled /> */}
        <Select
          label="City/District"
          value={selectedCityId}
          onChange={(e) => setSelectedCityId(e.target.value)}
          disabled={!isEditing}
        >
          {cities.map((city) => (
            <option key={city._id} value={city._id}>
              {city.name}
            </option>
          ))}
        </Select>
        <Input
          label="Zip Code"
          value={clubData.postalOrZip}
          disabled={!isEditing}
        />
        <Input
          label="Age Range"
          value={`${clubData.ageOfYoungestMember} - ${clubData.ageOfOldestMember}`}
          disabled
        />
      </div>

      <Section title="Club Declarations">
        {declarations.length ? (
          <ul className="text-sm">
            {declarations.map((dec) => (
              <li key={dec._id}>
                <Checkbox
                  label={dec.name}
                  checked={selectedDeclarationIds.includes(dec._id)}
                  onChange={(e) => handleDeclarationChange(e, dec._id)}
                  disabled={!isEditing}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p>No declarations available.</p>
        )}
      </Section>

      <Section title="Club Members">
        {clubData.members.map((memberData, idx) => (
          <MemberCard
            key={idx}
            memberData={memberData}
            index={idx}
            isEditing={isEditing}
          />
        ))}
      </Section>

      <Section title="Club Status">
        <div className="flex items-center">
          <span className="text-sm font-medium mr-2">Current Club Status:</span>
          <button
            onClick={handleStatusClick}
            className={`px-4 py-2 text-sm font-semibold rounded-md capitalize transition ${
              clubStatus === "approved"
                ? "bg-green-500 text-white"
                : clubStatus === "rejected"
                ? "bg-red-500 text-white"
                : clubStatus === "pending"
                ? "bg-yellow-300 text-black"
                : "bg-gray-500 text-white"
            }`}
            title="Click to change club status"
          >
            {clubStatus === "approved"
              ? "Approved"
              : clubStatus === "rejected"
              ? "Rejected"
              : clubStatus === "pending"
              ? "Pending"
              : "Processing"}
          </button>
        </div>
        <p className="text-xs text-gray-600 mt-1">
          Click the button to change the club status.
        </p>
      </Section>

      {/* Confirm Status Modal */}
      <ConfirmStatusModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmStatusChange}
        currentStatus={clubStatus}
        newStatusOptions={newStatusOptions}
        setNewStatus={setNewStatus}
      />
    </div>
  );
};

const Input = ({ label, value, onChange, disabled }) => (
  <div className="mb-2">
    <label className="text-sm font-medium text-primary">{label}:</label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`w-full mt-1 p-1 border rounded-md text-sm ${
        disabled ? "bg-gray-100" : "bg-white"
      }`}
    />
  </div>
);

const Checkbox = ({ label, checked, onChange, disabled }) => (
  <div className="flex items-center mb-1">
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className="mr-2"
    />
    <span className="font-bold text-primary text-sm">{label}</span>
  </div>
);

const Section = ({ title, children }) => (
  <div className="mb-4">
    <h3 className="text-lg font-bold mb-2">{title}</h3>
    {children}
  </div>
);

const MemberCard = ({ memberData, index, isEditing }) => {
  const { member } = memberData;
  return (
    <div className="mb-4">
      <div className="text-white bg-primary px-3 py-2 rounded-t-md font-bold text-sm">
        Club Member {index + 1}
      </div>
      <div className="px-3 py-2 grid grid-cols-4 gap-4 border border-gray-300 rounded-b-md">
        {/* <Input label="First Name" value={member.firstName} disabled />
        <Input label="Last Name" value={member.lastName} disabled /> */}
        <Input
          label="Full Name"
          value={`${member.firstName} ${member.lastName}`}
          disabled
        />

        <Input label="Email" value={member.email} disabled />
        <Input
          label="Phone/Mobile"
          value={
            member.phone && member.phone.countryCode
              ? `${member.phone.countryCode}${member.phone.number}`
              : member.phone && member.phone.number
              ? member.phone.number
              : "N/A"
          }
          disabled
        />
        <Input label="Username" value={member.username} disabled />
        <Input label="Gender" value={member.gender} disabled />
        <Input
          label="Date of Birth"
          value={
            member.dateOfBirth
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(member.dateOfBirth))
              : "N/A"
          }
          disabled
        />
        <Input label="Status" value={member.status} disabled />
        <Input
          label="Role"
          value={memberData.role === "key" ? "key member" : memberData.role}
          disabled
        />
        {/* <Input
          label="Online Status"
          value={member.isOnline ? "Online" : "Offline"}
          disabled
        /> */}
      </div>
    </div>
  );
};

export default ClubDetail;
