import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { useNavigate } from "react-router-dom";
import { put } from "../../utils/fetchAPI";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changeMessage, setChangeMessage] = useState("");
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setChangeMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await put("/admin/admins/change-password", {
        oldPassword,
        password: newPassword,
      });
      if (response.data && response.data.success) {
        setChangeMessage("Password change successful. Redirecting to login...");
        setTimeout(() => navigate("/login"), 2000);
      } else {
        setChangeMessage("Failed to change password. Try again.");
      }
    } catch (error) {
      setChangeMessage("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100">
      <div className="relative w-full max-w-[400px] text-center">
        <span className="text-2xl text-lightSecondary">Change Password</span>
        <div className="mt-4 bg-white shadow-md rounded-lg text-left">
          <div className="h-2 bg-primary rounded-t-md"></div>
          <div className="border px-8 py-6">
            {changeMessage && (
              <p className={`text-red-500 font-bold text-center`}>
                {changeMessage}
              </p>
            )}
            <>
              <label
                className="label block font-semibold"
                htmlFor="oldPassword"
              >
                Old Password
              </label>
              <div className="relative">
                <input
                  className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  className="absolute right-3 top-[60%] transform -translate-y-1/2 flex items-center"
                >
                  {showOldPassword ? (
                    <FaEyeSlash className="text-primary" />
                  ) : (
                    <FaEye className="text-primary" />
                  )}
                </button>
              </div>

              <label
                className="label mt-3 block font-semibold"
                htmlFor="newPassword"
              >
                New Password
              </label>
              <div className="relative">
                <input
                  className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-3 top-[60%] transform -translate-y-1/2 flex items-center"
                >
                  {showNewPassword ? (
                    <FaEyeSlash className="text-primary" />
                  ) : (
                    <FaEye className="text-primary" />
                  )}
                </button>
              </div>

              <label
                className="label mt-3 block font-semibold"
                htmlFor="confirmPassword"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-[60%] transform -translate-y-1/2 flex items-center"
                >
                  {showConfirmPassword ? (
                    <FaEyeSlash className="text-primary" />
                  ) : (
                    <FaEye className="text-primary" />
                  )}
                </button>
              </div>

              <div className="flex items-center mt-4">
                <button
                  onClick={handleChangePassword}
                  className="px-4 py-2 bg-secondary text-white rounded-md"
                >
                  Change Password
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
