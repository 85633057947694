import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import DataTable from "react-data-table-component";
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del, imageBaseURL, patch } from "../../utils/fetchAPI";
import ConfirmStatusModal from "../users/ConfirmStatusModal";
import ViewAdminModal from "./ViewAdminModal";

const AdminTable = ({
  admins: initialAdmins,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  userId,
}) => {
  const [admins, setAdmins] = useState(initialAdmins);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("Select Status");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newStatusOptions, setNewStatusOptions] = useState([]);
  const [newStatus, setNewStatus] = useState("");

  const navigate = useNavigate();

  const getImageUrl = (url, defaultImage) => {
    return url ? `${imageBaseURL}/${url}` : defaultImage;
  };

  useEffect(() => {
    let filteredAdmins = initialAdmins;

    if (search) {
      filteredAdmins = filteredAdmins.filter((admin) =>
        admin.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (statusFilter !== "Select Status" && statusFilter !== "all") {
      filteredAdmins = filteredAdmins.filter(
        (admin) => admin.status === statusFilter
      );
    }

    setAdmins(filteredAdmins);
  }, [statusFilter, search, initialAdmins]);

  const handleStatusChange = (userId, currentStatus) => {
    let options = [];
    if (currentStatus === "active") {
      options = ["inactive", "suspended"];
    } else if (currentStatus === "inactive") {
      options = ["active", "suspended"];
    } else if (currentStatus === "suspended") {
      options = ["active", "inactive"];
    }

    setSelectedUser({ _id: userId, currentStatus });
    setNewStatusOptions(options);
    setConfirmModalOpen(true);
  };

  const confirmStatusChange = async () => {
    if (!newStatus) {
      alert("Please select a new status.");
      return;
    }

    try {
      await patch(`/admin/admins/${selectedUser._id}`, { status: newStatus });
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin._id === selectedUser._id
            ? { ...admin, status: newStatus }
            : admin
        )
      );
      Swal.fire("Success", "Status updated successfully.", "success");
    } catch (error) {
      console.error("Error updating status:", error);
      Swal.fire("Error", "Failed to update status.", "error");
    } finally {
      setConfirmModalOpen(false);
      setNewStatus("");
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleViewAdmin = (id) => {
    setSelectedAdminId(id);
    setIsViewModalOpen(true);
  };

  const handleEditAdmin = (admin) => {
    setSelectedAdmin(admin);
    setIsEditModalOpen(true);
  };

  const handleDeleteAdmin = (adminId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        del(`admin/admins/${adminId}`)
          .then(() => {
            setAdmins((prevAdmins) =>
              prevAdmins.filter((admin) => admin._id !== adminId)
            );
            Swal.fire("Deleted!", "Admin deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting admin", error);
            Swal.fire("Error!", "Failed to delete admin.", "error");
          });
      }
    });
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Admins",
      cell: (row) => (
        <div className="flex items-center space-x-2">
          {row.profilePhoto ? (
            <img
              src={getImageUrl(row.profilePhoto, "/default-profile.jpg")}
              alt="Author"
              className="w-10 h-10 object-cover rounded-full"
            />
          ) : (
            <Avatar
              name={`${row.firstName} ${row.lastName}`}
              size="40"
              round={true}
            />
          )}
          <span>{`${row.firstName} ${row.lastName}`}</span>
        </div>
      ),
      wrap: true,
    },
    {
      name: "UserName",
      selector: (row) => row.username || "N/A",
    },
    {
      name: "Phone",
      selector: (row) =>
        row.phone ? `${row.phone.countryCode}${row.phone.number}` : "N/A",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Role",
      selector: (row) => row.role?.name || "N/A",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <button
            onClick={() => handleStatusChange(row._id, row.status)}
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.status === "active"
                ? "bg-green-200 text-green-800"
                : row.status === "inactive"
                ? "bg-red-200 text-red-800"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            {row.status}
          </button>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => handleViewAdmin(row._id)}
            className="text-blue-500 hover:text-blue-700"
          >
            <FaEye className="text-xl" />
          </button>

          <button
            onClick={() => navigate(`/edit-admin/${row._id}`)}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>

          {/* Conditionally render Edit and Delete buttons */}
          {userId !== row._id && (
            <>
              {/* <button
                onClick={() => navigate(`/edit-admin/${row._id}`)}
                className="text-yellow-500 hover:text-yellow-700"
              >
                <FaPencilAlt className="text-xl" />
              </button> */}
              <button
                onClick={() => handleDeleteAdmin(row._id)}
                className="text-red-500 hover:text-red-700"
              >
                <FaTrash className="text-xl" />
              </button>
            </>
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={admins}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full space-x-4">
            <h1 className="text-xl font-bold">Admin List</h1>
            <button
              onClick={() => navigate("/create-admin")}
              className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
              aria-label="Create admin"
            >
              <FaPlus className="mr-2" />
              Create Admin
            </button>
          </div>
        }
      />
      <ViewAdminModal
        isOpen={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        adminId={selectedAdminId}
      />
      {/* <EditAdmin
        isOpen={isEditModalOpen} // Control the visibility of EditAdmin modal
        onClose={() => setIsEditModalOpen(false)} // Close handler
        admin={selectedAdmin} // Pass the selected admin data
      /> */}
      <ConfirmStatusModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={confirmStatusChange}
        currentStatus={selectedUser?.currentStatus}
        newStatusOptions={newStatusOptions}
        setNewStatus={setNewStatus}
      />
    </div>
  );
};

export default AdminTable;
