import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWithParams } from "../../../utils/fetchAPI";
import ClubMemberProfessionsTable from "./clubMemberProfessionsTable"; // Adjust the import path as needed

const ClubMemberProfessions = () => {
  const [clubMemberProfessions, setClubMemberProfessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();

  const fetchClubMemberProfessions = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getWithParams("admin/club-member-professions", {
        page,
        limit,
      });

      if (response?.status === 200) {
        setClubMemberProfessions(response?.data?.data?.docs || []);
        setTotalRows(response?.data?.data?.totalDocs);
      } else {
        setClubMemberProfessions([]);
      }
    } catch (err) {
      setError("Failed to fetch club member professions");
      localStorage.removeItem("user");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchClubMemberProfessions(page, limit);
  }, [page, limit, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full">
      <ClubMemberProfessionsTable
        clubMemberProfessions={clubMemberProfessions}
        totalRows={totalRows}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
    </div>
  );
};

export default ClubMemberProfessions;
