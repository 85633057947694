import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Admins from "../pages/admins/admins";
import CreateAdmin from "../pages/admins/CreateAdmin";
import EditAdmin from "../pages/admins/EditAdmin";
import BulletinDetail from "../pages/bulletins/bulletinDetail";
import Bulletins from "../pages/bulletins/bulletins";
import CreateBulletin from "../pages/bulletins/createBulletin";
import ClubDetail from "../pages/clubs/ClubDetail";
import Clubs from "../pages/clubs/clubs";
import Dashboard from "../pages/dashborad/dashborad";
import NotFound from "../pages/error/404";
import CreateEvent from "../pages/events/createEvent";
import EditEvent from "../pages/events/EditEvent";
import { default as ViewEvents } from "../pages/events/viewEvents";
import LogIn from "../pages/login/login";
import ResetPassword from "../pages/login/reset-password/reset-password";
import ChangePassword from "../pages/profile/ChangePassword";
import ProfilePage from "../pages/profile/ProfilePage";
import ClubDeclarations from "../pages/settings/clubDeclarations/clubDeclarations";
import FocusArea from "../pages/settings/clubFocusAreas/clubFocusAreas";
import ClubMemberInterestReasons from "../pages/settings/ClubMemberInterestReasons/ClubMemberInterestReasons";
import ClubMemberProfessions from "../pages/settings/ClubMemberProfessions/ClubMemberProfessions";
import ClubSpirits from "../pages/settings/clubSpirits/clubSpirits";
import GeoCities from "../pages/settings/GeoCities/GeoCities";
import GeoStates from "../pages/settings/GeoStates/GeoStates";
import Settings from "../pages/settings/settings";
import Users from "../pages/users/users";
import PrivateRoute from "./private-route";
import PublicRoute from "./public-route";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/* Redirect the root to either dashboard or login based on authentication */}
        <Route path="/" element={<Navigate to="/dashboard" />} />

        {/* Public routes */}
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<LogIn />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/admins" element={<Admins />} />
          <Route path="/create-admin" element={<CreateAdmin />} />
          <Route path="/edit-admin/:adminId" element={<EditAdmin />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/focus-areas" element={<FocusArea />} />
          <Route
            path="/settings/club-declarations"
            element={<ClubDeclarations />}
          />
          <Route path="/settings/club-spirits" element={<ClubSpirits />} />
          <Route
            path="/settings/club-member-interest-reasons"
            element={<ClubMemberInterestReasons />}
          />
          <Route
            path="/settings/club-member-professions"
            element={<ClubMemberProfessions />}
          />
          <Route path="/settings/city" element={<GeoCities />} />
          <Route path="/settings/state" element={<GeoStates />} />
          <Route path="/events/create" element={<CreateEvent />} />
          <Route path="/events/view" element={<ViewEvents />} />
          <Route path="/events/edit/:id" element={<EditEvent />} />
          <Route path="/clubs" element={<Clubs />} />
          <Route path="/clubs/:id" element={<ClubDetail />} />
          <Route path="/bulletins" element={<Bulletins />} />
          <Route path="/bulletins/:id" element={<BulletinDetail />} />
          <Route path="/bulletins/create" element={<CreateBulletin />} />
        </Route>

        {/* Catch-all for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
