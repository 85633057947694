import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { baseURL, post } from "../../utils/fetchAPI";
import { LocalStorageService } from "../../utils/local-storage";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [resetMessage, setResetMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    try {
      const response = await post(`${baseURL}admin/auth/login`, {
        email,
        password,
      });

      if (response.data && response.data.success) {
        const { accessToken } = response.data.data;
        LocalStorageService.set("user", {
          token: accessToken,
        });
        navigate("/dashboard");
      } else {
        setErrorMessage("Invalid login credentials");
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data.message || "An error occurred during login"
        );
      } else {
        setErrorMessage("Network error. Please try again.");
      }
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await post("/admin/admins/forgot-password", {
        email: forgotPasswordEmail,
      });
      if (response.data && response.data.success) {
        setResetMessage("Password reset link sent to your email.");
      } else {
        setResetMessage("Error sending reset link. Try again.");
      }
    } catch (error) {
      setResetMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative px-6 py-3 mx-auto w-full sm:w-96 text-center">
        {/* Login form */}
        <span className="text-2xl text-lightSecondary">3ZERO Admin Login</span>
        <div className="mt-4 bg-white shadow-md rounded-lg text-left">
          <div className="h-2 bg-primary rounded-t-md"></div>
          <div className="border px-8 py-6">
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <label className="label block font-semibold" htmlFor="email">
                Email
              </label>
              <input
                className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label
                className="label mt-3 block font-semibold"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-[55%] transform -translate-y-1/2 flex items-center"
                >
                  {showPassword ? (
                    <FaEyeSlash className="text-primary" />
                  ) : (
                    <FaEye className="text-primary" />
                  )}
                </button>
              </div>
              <div className="flex items-center mt-4">
                <button
                  type="submit"
                  className="px-4 py-2 bg-secondary text-white rounded-md"
                >
                  Login
                </button>
                <button
                  type="button"
                  onClick={() => setShowForgotPasswordModal(true)}
                  className="ml-4 text-blue-500 hover:underline"
                >
                  Forgot Password?
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* Forgot Password Modal */}
        {showForgotPasswordModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
              <button
                onClick={() => setShowForgotPasswordModal(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                <FaTimes />
              </button>
              <h2 className="text-xl font-semibold mb-4">Forgot Password</h2>
              {resetMessage && <p className="text-green-500">{resetMessage}</p>}
              <label className="block font-semibold mb-2">Email</label>
              <input
                className="w-full h-10 mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                type="email"
                placeholder="Enter your email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
              />
              <div className="flex items-center mt-4">
                <button
                  onClick={handleForgotPassword}
                  className="px-4 py-2 bg-secondary text-white rounded-md"
                >
                  Send Reset Link
                </button>
                <button
                  onClick={() => setShowForgotPasswordModal(false)}
                  className="ml-4 text-gray-500 hover:underline"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
