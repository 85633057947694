import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import DataTable from "react-data-table-component";
import { FaEye, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del, imageBaseURL } from "../../utils/fetchAPI";

const BulletinTable = ({
  bulletins: initialBulletins,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  search,
  setSearch,
  handleSearch,
  status,
  setStatus,
  createdByModel,
  setCreatedByModel,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBulletin, setSelectedBulletin] = useState(null);
  const [bulletins, setBulletins] = useState(initialBulletins);

  const navigate = useNavigate();

  const getImageUrl = (url, defaultImage) => {
    return url ? `${imageBaseURL}/${url}` : defaultImage;
  };

  useEffect(() => {
    setBulletins(initialBulletins);
  }, [initialBulletins]);

  const formatDateTime = (dateString, timezone) => {
    const dt = DateTime.fromISO(dateString, { zone: "utc" }).setZone(timezone);

    const datePart = dt.toFormat("dd/MM/yyyy");
    const timePart = dt.toFormat("hh:mm a");

    return `${datePart} (${timePart})`;
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
      width: "300px",
    },
    // {
    //   name: "Club",
    //   selector: (row) => row.club?.name || "Created by Admin",
    //   sortable: true,
    //   wrap: true,
    //   width: "200px",
    // },
    // {
    //   name: "Category",
    //   selector: (row) => row.category?.name || "N/A",
    //   sortable: true,
    // },
    {
      name: "Author",
      cell: (row) => (
        <div className="flex items-center space-x-2">
          {row.createdBy?.profilePhoto ? (
            <img
              src={getImageUrl(
                row.createdBy?.profilePhoto,
                "/default-profile.jpg"
              )}
              alt="Author"
              className="w-10 h-10 object-cover rounded-full"
            />
          ) : (
            <Avatar
              name={`${
                row.createdBy
                  ? `${row.createdBy.firstName || "Super"} ${
                      row.createdBy.lastName || "Admin"
                    }`
                  : row.createdByModel
              }`}
              size="40"
              round={true}
            />
          )}
          <span>
            {/* {row.createdBy
              ? `${row.createdBy.firstName} ${row.createdBy.lastName}`
              : "Super Admin"} */}
            {row.createdBy
              ? `${row.createdBy.firstName} ${row.createdBy.lastName}`
              : row.createdByModel || "Super Admin"}
          </span>
        </div>
      ),
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Publish Date",
      selector: (row) => formatDateTime(row.publishDate),
      sortable: true,
      wrap: true,
      width: "200px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.status === "published"
                ? "bg-green-200 text-green-800"
                : row.status === "pending"
                ? "bg-yellow-200 text-yellow-800"
                : row.status === "draft"
                ? "bg-blue-200 text-blue-800"
                : "bg-red-200 text-red-800"
            }`}
          >
            {row.status}
          </h2>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => navigate(`/bulletins/${row._id}`)}
            className="text-blue-500 hover:text-blue-700"
          >
            <FaEye className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteBulletin(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleDeleteBulletin = (bulletinId, banners = []) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const validBanners =
          Array.isArray(banners) && banners.length > 0
            ? banners.filter((bannerPath) => bannerPath)
            : [];

        const deleteBanners = validBanners.length
          ? Promise.all(
              validBanners.map((bannerPath) => {
                return del(
                  `/admin/bulletins/banners/${bulletinId}?path=${bannerPath}`
                );
              })
            )
          : Promise.resolve();

        deleteBanners
          .then(() => del(`admin/bulletins/${bulletinId}`))
          .then(() => {
            setBulletins((prevBulletins) =>
              prevBulletins.filter((bulletin) => bulletin._id !== bulletinId)
            );
            Swal.fire("Deleted!", "Bulletin deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting bulletin", error);
            Swal.fire("Error!", "Failed to delete bulletin.", "error");
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={bulletins}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Bulletins</h1>
            <div className="flex items-center space-x-2">
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by status"
              >
                <option value="">Select Status</option>
                <option value="published">Published</option>
                <option value="trash">Trash</option>
                <option value="draft">Draft</option>
                <option value="pending">Pending</option>
              </select>

              <select
                value={createdByModel}
                onChange={(e) => setCreatedByModel(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by author type"
              >
                <option value="">Select Autor</option>
                <option value="User">User</option>
                <option value="Admin">Admin</option>
              </select>

              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search bulletins..."
                className="p-2 border rounded"
                aria-label="Search bulletins"
              />
              <button
                onClick={handleSearch}
                className="ml-2 p-2 bg-blue-500 text-white rounded"
              >
                Search
              </button>
              <button
                onClick={() => navigate("/bulletins/create")}
                className="ml-4 bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
                aria-label="Create bulletin"
              >
                <FaPlus className="mr-2" /> Create Bulletin
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default BulletinTable;
