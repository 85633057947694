import React from "react";
import {
  FaBriefcase,
  FaFileSignature,
  FaFlag,
  FaLightbulb,
  FaMapMarkerAlt,
  FaMapSigns,
  FaUserTie,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const settingsModules = [
  {
    id: 1,
    name: "Club Focus Areas",
    icon: <FaFlag />,
    route: "/settings/focus-areas",
  },
  {
    id: 2,
    name: "Club Declarations",
    icon: <FaFileSignature />,
    route: "/settings/club-declarations",
  },
  {
    id: 3,
    name: "Club Spirits",
    icon: <FaLightbulb />,
    route: "/settings/club-spirits",
  },
  {
    id: 4,
    name: "Club Member Interest Reasons",
    icon: <FaUserTie />,
    route: "/settings/club-member-interest-reasons",
  },
  {
    id: 5,
    name: "Club Member Professions",
    icon: <FaBriefcase />,
    route: "/settings/club-member-professions",
  },
  {
    id: 6,
    name: "Geo Location - City",
    icon: <FaMapMarkerAlt />,
    route: "/settings/city",
  },
  {
    id: 7,
    name: "Geo Location - State/District",
    icon: <FaMapSigns />,
    route: "/settings/state",
  },
  // {
  //   id: 5,
  //   name: "General Settings",
  //   icon: <FaCog />,
  //   route: "/settings/general",
  // },
];

const Settings = () => {
  const navigate = useNavigate();

  return (
    <div style={{ flexGrow: 1, padding: 20 }}>
      {/* <h1 className="text-2xl font-semibold mb-4">Settings</h1> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 max-w-screen-xl mx-auto">
        {settingsModules.map((module) => (
          <div
            key={module.id}
            onClick={() => navigate(module.route)}
            className="cursor-pointer border border-gray-300 rounded-lg shadow-lg p-6 flex flex-col justify-center items-center text-center hover:bg-gray-100 transition"
          >
            <div className="text-4xl text-primary mb-2">{module.icon}</div>
            <h2 className="text-sm font-medium">{module.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
