import { DateTime } from "luxon";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del } from "../../../utils/fetchAPI";
import CreateClubMemberProfessionModal from "./CreateClubMemberProfessionModal"; // Adjust the import path as needed
import EditClubMemberProfessionModal from "./EditClubMemberProfessionModal"; // Adjust the import path as needed

const ClubMemberProfessionsTable = ({
  clubMemberProfessions: initialClubMemberProfessions,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedClubMemberProfession, setSelectedClubMemberProfession] =
    useState(null);
  const [clubMemberProfessions, setClubMemberProfessions] = useState(
    initialClubMemberProfessions
  );

  const navigate = useNavigate();

  const formatDateTime = (dateString) => {
    const dt = DateTime.fromISO(dateString, { zone: "utc" }).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    return dt;
  };

  const handleClubMemberProfessionCreate = (newClubMemberProfession) => {
    setClubMemberProfessions((prevProfessions) => [
      newClubMemberProfession,
      ...prevProfessions,
    ]);
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Profession",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      width: "300px",
    },
    {
      name: "Description",
      selector: (row) => row.description || "N/A",
      sortable: false,
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      wrap: true,
      width: "170px",
    },
    {
      name: "Updated At",
      selector: (row) => formatDateTime(row.updatedAt),
      sortable: true,
      wrap: true,
      width: "170px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.isActive ? "bg-green-200" : "bg-red-200"
            }`}
          >
            {row.isActive ? "Active" : "Inactive"}
          </h2>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => {
              setSelectedClubMemberProfession(row);
              setIsEditModalOpen(true);
            }}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteClubMemberProfession(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleClubMemberProfessionUpdate = (updatedProfession) => {
    setClubMemberProfessions((prevProfessions) =>
      prevProfessions.map((profession) =>
        profession._id === updatedProfession._id
          ? { ...profession, ...updatedProfession }
          : profession
      )
    );
  };

  const handleDeleteClubMemberProfession = (professionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        del(`admin/club-member-professions/${professionId}`)
          .then(() => {
            setClubMemberProfessions((prevProfessions) =>
              prevProfessions.filter(
                (profession) => profession._id !== professionId
              )
            );
            Swal.fire(
              "Deleted!",
              "Club member profession deleted successfully.",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error deleting club member profession", error);
            Swal.fire(
              "Error!",
              "Failed to delete club member profession.",
              "error"
            );
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={clubMemberProfessions}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Club Member Professions</h1>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
              aria-label="Create club member profession"
            >
              <FaPlus className="mr-2" /> Create Club Member Profession
            </button>
          </div>
        }
      />
      <EditClubMemberProfessionModal
        clubMemberProfession={selectedClubMemberProfession}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onClubMemberProfessionUpdate={handleClubMemberProfessionUpdate}
      />
      <CreateClubMemberProfessionModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onClubMemberProfessionCreate={handleClubMemberProfessionCreate}
      />
    </div>
  );
};

export default ClubMemberProfessionsTable;
