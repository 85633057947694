import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWithParams } from "../../../utils/fetchAPI";
import ClubSpiritsTable from "./clubSpiritsTable"; // Adjust the import path as needed

const ClubSpirits = () => {
  const [clubSpirits, setClubSpirits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();

  const fetchClubSpirits = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getWithParams("admin/club-spirits", {
        page,
        limit,
      });

      if (response?.status === 200) {
        setClubSpirits(response?.data?.data?.docs || []);
        setTotalRows(response?.data?.data?.totalDocs);
      } else {
        setClubSpirits([]);
      }
    } catch (err) {
      setError("Failed to fetch club spirits");
      localStorage.removeItem("user");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchClubSpirits(page, limit);
  }, [page, limit, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full">
      <ClubSpiritsTable
        clubSpirits={clubSpirits}
        totalRows={totalRows}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
      />
    </div>
  );
};

export default ClubSpirits;
