import { DateTime } from "luxon";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del } from "../../../utils/fetchAPI";
import CreateGeoStateModal from "./CreateGeoStateModal";
import EditGeoStateModal from "./EditGeoStateModal";

const GeoStatesTable = ({
  geoStates: initialGeoStates,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  search,
  setSearch,
  handleSearch,
  status,
  setStatus,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedGeoState, setSelectedGeoState] = useState(null);
  const [geoStates, setGeoStates] = useState(initialGeoStates);

  const navigate = useNavigate();

  const formatDateTime = (dateString) => {
    const dt = DateTime.fromISO(dateString, { zone: "utc" }).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    return dt;
  };

  const handleGeoStateCreate = (newGeoState) => {
    setGeoStates((prevGeoStates) => [newGeoState, ...prevGeoStates]);
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "State/Province",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Country",
      selector: (row) => row.country?.name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Country Short Code",
      selector: (row) => row.countryShortCode,
      sortable: false,
      wrap: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.status === true ? "bg-green-200" : "bg-red-200"
            }`}
          >
            {row.status === true ? "Active" : "Inactive"}
          </h2>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => {
              setSelectedGeoState(row);
              setIsEditModalOpen(true);
            }}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteGeoState(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleGeoStateUpdate = (updatedGeoState) => {
    setGeoStates((prevGeoStates) =>
      prevGeoStates.map((geoState) =>
        geoState._id === updatedGeoState._id
          ? { ...geoState, ...updatedGeoState }
          : geoState
      )
    );
  };

  const handleDeleteGeoState = (geoStateId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        del(`admin/geo-states-provinces/${geoStateId}`)
          .then(() => {
            setGeoStates((prevGeoStates) =>
              prevGeoStates.filter((geoState) => geoState._id !== geoStateId)
            );
            Swal.fire("Deleted!", "Geo state deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting geo state", error);
            Swal.fire("Error!", "Failed to delete geo state.", "error");
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={geoStates}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Geo States</h1>
            <div className="flex items-center space-x-2">
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by status"
              >
                <option value="">Select Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>

              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search state..."
                className="p-2 border rounded"
                aria-label="Search bulletins"
              />
              <button
                onClick={handleSearch}
                className="ml-2 p-2 bg-blue-500 text-white rounded"
              >
                Search
              </button>
              <button
                onClick={() => setIsCreateModalOpen(true)}
                className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
                aria-label="Create geo state"
              >
                <FaPlus className="mr-2" /> Create Geo State
              </button>
            </div>
          </div>
        }
      />
      <EditGeoStateModal
        geoState={selectedGeoState}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onGeoStateUpdate={handleGeoStateUpdate}
      />
      <CreateGeoStateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onGeoStateCreate={handleGeoStateCreate}
      />
    </div>
  );
};

export default GeoStatesTable;
