import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { get, post } from "../../../utils/fetchAPI";

Modal.setAppElement("#root");

const CreateGeoCityModal = ({ isOpen, onClose, onGeoCityCreate }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [countryShortCode, setCountryShortCode] = useState("");
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cityNumber, setCityNumber] = useState(1);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await get("/geo-locations/countries");
        if (response.status === 200) setCountries(response.data.data);
      } catch {
        toast.error("Error fetching countries.");
      }
    };
    fetchCountries();
  }, []);

  const handleCityNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setCityNumber(isNaN(value) || value <= 0 ? 1 : value);
  };

  const handleSave = async () => {
    setLoading(true);
    const parsedLatitude = parseFloat(latitude);
    const parsedLongitude = parseFloat(longitude);

    if (isNaN(parsedLatitude) || isNaN(parsedLongitude)) {
      toast.error("Latitude and Longitude must be valid numbers.");
      setLoading(false);
      return;
    }

    const geoCityData = {
      country: selectedCountryId,
      countryShortCode,
      stateOrProvinceId: null,
      cityNumber,
      name,
      latitude: parsedLatitude,
      longitude: parsedLongitude,
      status,
    };

    try {
      const response = await post("/admin/geo-cities", geoCityData);
      if (response.status === 200) {
        toast.success("Geo city created successfully!");
        onGeoCityCreate(geoCityData);
        onClose();
      } else {
        toast.error(response.message || "Failed to create geo city.");
      }
    } catch (err) {
      toast.error("Error creating geo city. Please try again.");
      console.error("Error creating geo city", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-2"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-[500px] max-h-[720px] overflow-auto rounded-lg bg-white shadow-lg p-4">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-bold mb-2">Create Geo City</h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-primary transition-all hover:bg-primary/10"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="mb-2 col-span-2">
            <label className="block text-xs font-medium text-primary">
              Country:
            </label>
            <select
              value={selectedCountryId}
              onChange={(e) => setSelectedCountryId(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country._id} value={country._id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              Country Short Code:
            </label>
            <input
              type="text"
              value={countryShortCode}
              onChange={(e) => setCountryShortCode(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter country short code"
            />
          </div>

          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              City Name:
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter city name"
            />
          </div>

          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              Latitude:
            </label>
            <input
              type="text"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter latitude"
            />
          </div>

          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              Longitude:
            </label>
            <input
              type="text"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter longitude"
            />
          </div>

          <div className="mb-2">
            <label className="block text-xs font-medium text-primary">
              City Number:
            </label>
            <input
              type="number"
              value={cityNumber}
              onChange={handleCityNumberChange}
              className="mt-1 border rounded-md p-1 w-full text-xs"
              placeholder="Enter city number"
              min="1"
            />
          </div>

          <div className="mb-2 flex items-center col-span-2">
            <label className="block text-xs font-medium text-primary mr-2">
              Is Active:
            </label>
            <input
              type="checkbox"
              checked={status === true}
              onChange={(e) => setStatus(e.target.checked ? true : false)}
              className="h-4 w-4 text-primary"
            />
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-secondary rounded-md text-white text-xs"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="ml-2 px-4 py-2 bg-primary rounded-md text-white text-xs"
            disabled={loading}
          >
            {loading ? "Saving..." : "Create"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateGeoCityModal;
